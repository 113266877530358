import { createFetchOptions, fetchHandler, useFetch } from "./use-fetch";
import { useProperty } from "./use-property";

export const useScenes = (
  opts = {
    enable: true,
  },
) => {
  const { currentPropertyId } = useProperty();
  const fetchOptions = createFetchOptions(currentPropertyId, "scenes");
  const SCENES = fetchOptions.key;
  const headers = fetchOptions.headers;

  const scenes = useFetch({
    queryKey: SCENES,
    staleTime: 1000 * 60 * 60 * 24,
    queryFn: fetchHandler({ useCase: "ListScenes", headers }),
    enabled: opts.enable,
    placeholderData: [],
  });

  const getSceneById = (id) => {
    return scenes.data.find((scene) => scene.id === id);
  };

  const getSceneByName = (name) => {
    return scenes.data.find((scene) => scene.name === name);
  };

  return {
    ...scenes,
    getSceneById,
    getSceneByName,
  };
};
