import { cn } from "@/lib/utils";
import { gsap } from "gsap";
import Draggable from "gsap/Draggable";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import DeleteTimeEntryModal from "./DeleteTimeEntryModal";
import TimeTrackingFooter from "./TimeTrackingFooter";
import TimeTrackingHeader from "./TimeTrackingHeader";

import {
  differenceInMinutes,
  format,
  get24HourTimeString,
  parse24HourTimeString,
  set,
} from "utils/dates";

import Button from "components/common/Button";
import FadeTransition from "components/common/FadeTransisiton";
import { ReactComponent as Arrow } from "../icons/arrow.svg";
import { ReactComponent as CalendarIcon } from "../icons/calendar.svg";
import { ReactComponent as RightArrow } from "../icons/right-arrow.svg";
import { ReactComponent as XIcon } from "../icons/x.svg";

import * as utils from "./TimeTracking.utils";

import { useLiveTimeCounter } from "hooks/use-live-time-counter";
import { useTickets } from "hooks/use-tickets";
import { isToday as dateIsToday } from "utils/dates";
import { getRequesterName } from "../Tickets/Tickets.utils";

gsap.registerPlugin(Draggable);

const TimeSheetDayView = ({ day, timeEntries = [], timeTracking }) => {
  const entries = timeEntries;

  const openEntry = entries.find((e) => !e.end);

  const totalWorkHours = utils.getTotalWorkHours(entries);

  const workStatus = utils.getWorkStatus(openEntry);

  const isDriving = workStatus === utils.workingEnum.driving;
  const isWorking = workStatus === utils.workingEnum.working;
  const isBreak = workStatus === utils.workingEnum.break;

  const [editTimeBlockId, setEditTimeBlockId] = React.useState("");
  const [deleteTimeBlockId, setDeleteTimeBlockId] = React.useState("");
  const [showAddTimeBlock, setShowAddTimeBlock] = React.useState(false);

  const [clockTimeDiscriminator, setClockDiscriminator] = React.useState(
    workStatus || utils.workingEnum.working,
  );
  const [clockTicketId, setClockTicketId] = React.useState("");

  const { data: ticketData } = useTickets({ status: "open", mine: true });

  const propertyTickets = ticketData[0]?.tickets;

  const navigate = useNavigate();

  const dayTitle = format(day ? new Date(day) : new Date(), "MMM dd, yyyy");

  const isToday = day
    ? format(day, "MM-dd-yy") === format(new Date(), "MM-dd-yy")
    : true;

  const completedEntries = entries.filter((entry) => entry.end);

  const heightPerHour = 60;
  const heightPerMin = heightPerHour / 60;

  const clockRunning = Boolean(workStatus);

  const stopClock = () => {
    if (isDriving) {
      timeTracking.stopDriving();
    }
    if (isWorking) {
      timeTracking.stopWorking();
    }

    if (isBreak) {
      timeTracking.stopBreak();
    }

    setClockDiscriminator(utils.workingEnum.working);
    setClockTicketId("");
  };

  const startClock = () => {
    if (clockTimeDiscriminator === utils.workingEnum.working) {
      timeTracking.startWorking(clockTicketId);
    }

    if (clockTimeDiscriminator === utils.workingEnum.driving) {
      timeTracking.startDriving(clockTicketId);
    }

    if (clockTimeDiscriminator === utils.workingEnum.break) {
      timeTracking.startBreak();
    }
  };

  const entryToEdit = editTimeBlockId
    ? entries.find((e) => e.id === editTimeBlockId)
    : null;

  const addOrEditEntry = ({ type, startTime, endTime }) => {
    const id = showAddTimeBlock ? null : editTimeBlockId;

    const start = id ? new Date(entryToEdit.start) : new Date(day);
    const end = id ? new Date(entryToEdit.end) : new Date(day);

    const { hours: startHours, minutes: startMinutes } =
      parse24HourTimeString(startTime);
    const { hours: endHours, minutes: endMinutes } =
      parse24HourTimeString(endTime);

    const newStartDateTime = set(start, {
      hours: startHours,
      minutes: startMinutes,
      seconds: 0,
      milliseconds: 0,
    });

    const newEndDateTime = set(end, {
      hours: endHours,
      minutes: endMinutes,
      seconds: 0,
      milliseconds: 0,
    });

    const entryData = {
      id,
      start: newStartDateTime.toISOString(),
      end: newEndDateTime.toISOString(),
      discriminator: type,
    };

    timeTracking.changeEntry(entryData);
    clearEdits();
  };

  const deleteEntry = (id) => {
    timeTracking.deleteEntry({ id });
    clearEdits();
  };

  const clearEdits = () => {
    setEditTimeBlockId("");
    setShowAddTimeBlock(false);
    setDeleteTimeBlockId("");
  };

  const goHome = () => {
    navigate("/");
  };

  return (
    <>
      <TimeSheetHeader
        title={dayTitle}
        date={day ? new Date(day) : new Date()}
        totalWorkHours={totalWorkHours}
        workStatus={workStatus}
        openEntryInitialStartTime={openEntry?.start}
        onChange={(date) => navigate(`/timesheets/${date}`)}
        onAdd={() => setShowAddTimeBlock(true)}
        onClose={goHome}
      />
      <div className="container z-40 mx-auto h-screen overflow-scroll bg-dark-gray px-4 h-screen-ios">
        <TimeSheetHours>
          {completedEntries.map((entry) => {
            const startDate = new Date(entry.start);
            const endDate = new Date(entry.end);
            const diff = endDate - startDate;
            const height = (diff / 1000 / 60) * heightPerMin;

            const top =
              (startDate.getHours() + startDate.getMinutes() / 60) *
                heightPerHour +
              32;

            const hours = Math.abs(
              differenceInMinutes(endDate, startDate) / 60,
            ).toFixed(1);

            return (
              <TimeEntry
                key={entry.id}
                isSelected={editTimeBlockId === entry.id}
                height={height}
                top={top}
                workStatus={entry.discriminator}
                hours={hours + " hours"}
                onClick={() => setEditTimeBlockId(entry.id)}
              />
            );
          })}
        </TimeSheetHours>
      </div>
      {!editTimeBlockId && isToday && (
        <TimeTrackingFooter>
          <select
            name="type"
            className="w-full appearance-none rounded-lg bg-zinc-700 p-2.5 text-lg disabled:text-muted"
            value={clockTimeDiscriminator}
            disabled={clockRunning}
            onChange={(e) => setClockDiscriminator(e.target.value)}
          >
            <option value={utils.workingEnum.working}>
              {utils.labels.working}
            </option>
            <option value={utils.workingEnum.driving}>
              {utils.labels.driving}
            </option>
            <option value={utils.workingEnum.break}>
              {utils.labels.break}
            </option>
          </select>

          {[utils.workingEnum.driving, utils.workingEnum.working].includes(
            clockTimeDiscriminator,
          ) && (
            <select
              className="w-full appearance-none rounded-lg bg-zinc-700 p-2.5 text-lg disabled:text-muted"
              value={clockTicketId}
              disabled={clockRunning}
              onChange={(e) => setClockTicketId(e.target.value)}
            >
              <option value="" disabled>
                {propertyTickets.length === 0
                  ? "No tickets"
                  : "Select a ticket"}
              </option>
              {propertyTickets.map((ticket) => {
                const requesterName = getRequesterName({ ticket });
                const ticketNumber = ticket.number;
                let title = `#${ticketNumber} - ${requesterName}`;

                if (ticket.scheduleDate) {
                  const isForToday = dateIsToday(new Date(ticket.scheduleDate));
                  if (!isForToday)
                    title += ` - ${format(new Date(ticket.scheduleDate), "MM/DD/YY")}`;
                }
                return (
                  <option key={ticket.id} value={ticket.id}>
                    {title}
                  </option>
                );
              })}
            </select>
          )}

          <Button
            onClick={clockRunning ? stopClock : startClock}
            className={cn("w-full rounded-lg text-lg font-normal text-white", {
              "border-zinc-700 bg-zinc-700":
                clockTimeDiscriminator === utils.workingEnum.break,
              "border-emerald-800 bg-emerald-800":
                clockTimeDiscriminator === utils.workingEnum.working,
              "border-purple-slate bg-purple-slate":
                clockTimeDiscriminator === utils.workingEnum.driving,
            })}
          >
            {clockRunning ? "Stop Clock" : "Start Clock"}
          </Button>
        </TimeTrackingFooter>
      )}
      <FadeTransition show={entryToEdit}>
        <TimeBlockEditor
          key={entryToEdit?.id}
          title="Edit time block"
          initialStartTime={get24HourTimeString(entryToEdit?.start)}
          initialEndTime={get24HourTimeString(entryToEdit?.end)}
          initialType={entryToEdit?.discriminator}
          onClose={clearEdits}
          onSave={addOrEditEntry}
          onDelete={() => setDeleteTimeBlockId(entryToEdit?.id)}
        />
      </FadeTransition>

      <FadeTransition show={showAddTimeBlock}>
        <TimeBlockEditor
          title="Add time block"
          initialStartTime={get24HourTimeString(new Date())}
          initialEndTime={get24HourTimeString(
            new Date().setHours(new Date().getHours() + 1),
          )}
          initialType="working"
          onClose={clearEdits}
          onSave={addOrEditEntry}
        />
      </FadeTransition>

      {deleteTimeBlockId && (
        <DeleteTimeEntryModal
          onConfirm={() => deleteEntry(deleteTimeBlockId)}
          onCancel={clearEdits}
        />
      )}
    </>
  );
};

const TimeEntry = ({
  height = 0,
  top = 0,
  workStatus,
  hours,
  isSelected = false,
  onClick,
}) => {
  // get the y offset of the start time and add it as a top css property

  const label = utils.labels[workStatus];

  return (
    <div
      className={cn(
        {
          "border-teal-400 bg-emerald-800 text-teal-400":
            workStatus === utils.workingEnum.working,
          "border-indigo-300 bg-purple-slate text-indigo-300":
            workStatus === utils.workingEnum.driving,
          "border-zinc-700 bg-zinc-700 text-white":
            workStatus === utils.workingEnum.break,
          "left-10 border-opacity-100": isSelected,
          "left-14 border-opacity-0": !isSelected,
        },
        "absolute right-0 flex items-center justify-center rounded-md border-2 text-sm font-medium transition-all duration-300",
      )}
      style={{ height, top }}
      onClick={onClick}
    >
      {label}
      <span className="ml-2 text-white">{hours}</span>
    </div>
  );
};

const HourBlock = ({ label }) => {
  return (
    <div className="flex items-start" style={{ height: 60 }}>
      <span className="-mt-2 mr-2 w-10 text-xs uppercase text-muted">
        {label}
      </span>
      <span className="h-px grow bg-muted"></span>
    </div>
  );
};

const TimeBlockEditor = ({
  title = "Add time block",
  initialStartTime = "",
  initialEndTime = "",
  initialType = "",
  onClose,
  onSave,
  onDelete,
}) => {
  const [error, setError] = React.useState("");
  const [type, setType] = React.useState(initialType);
  const [startTime, setStartTime] = React.useState(initialStartTime);
  const [endTime, setEndTime] = React.useState(initialEndTime);

  const validate = () => {
    const { hours: startHours, minutes: startMinutes } =
      parse24HourTimeString(startTime);
    const { hours: endHours, minutes: endMinutes } =
      parse24HourTimeString(endTime);

    // start time cannot be after end time
    const start = new Date();
    start.setHours(startHours);
    start.setMinutes(startMinutes);
    start.setSeconds(0);
    start.setMilliseconds(0);

    const end = new Date();
    end.setHours(endHours);
    end.setMinutes(endMinutes);
    end.setSeconds(0);
    end.setMilliseconds(0);

    if (start > end) return false;
    return true;
  };

  const handleSave = (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) {
      setError("Start time cannot be after end time");
      return;
    }

    onSave({ type, startTime, endTime });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "startTime") setStartTime(value);
    if (name === "endTime") setEndTime(value);
    setError("");
  };

  return (
    <div className="fixed bottom-0 left-1/2 z-40 flex w-full max-w-2xl -translate-x-1/2 flex-col justify-start gap-2 rounded-t-xl bg-zinc-800 px-6 py-4 shadow">
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-normal text-white">{title}</h3>
        <button onClick={onClose}>
          <XIcon className="w-4" />
        </button>
      </div>

      <div
        className={cn("text-red-500 transition-all duration-200", {
          "h-0 opacity-0": !error,
          "h-6 opacity-100": error,
        })}
      >
        {error}
      </div>

      <div className="flex flex-col gap-y-4">
        <div className="w-full">
          <label className="mb-2 block">Type</label>
          <div className="relative flex items-center">
            <select
              value={type}
              onChange={(e) => setType(e.target.value)}
              className="w-full appearance-none rounded-lg bg-zinc-700 p-4"
            >
              <option value={utils.workingEnum.working}>
                {utils.labels.working}
              </option>
              <option value={utils.workingEnum.driving}>
                {utils.labels.driving}
              </option>
              <option value={utils.workingEnum.break}>
                {utils.labels.break}
              </option>
            </select>
            <Arrow className="absolute right-4" />
          </div>
        </div>

        <div className="mb-2 flex gap-4">
          <div className="flex-1">
            <label className="mb-2 block">Start time</label>
            <input
              type="time"
              name="startTime"
              className="w-full appearance-none rounded-lg bg-zinc-700 p-4 dark:text-white dark:[color-scheme:dark]"
              value={startTime}
              onChange={handleInput}
            />
          </div>
          <div className="flex-1">
            <label className="mb-2 block">End time</label>
            <input
              type="time"
              name="endTime"
              className="w-full appearance-none rounded-lg bg-zinc-700 p-4 text-white dark:[color-scheme:dark]"
              min={startTime}
              value={endTime}
              onChange={handleInput}
            />
          </div>
        </div>
        <Button
          className="w-full"
          onClick={handleSave}
          disabled={Boolean(error)}
        >
          Save
        </Button>
        {onDelete && (
          <Button
            className="w-full border-red-600 bg-red-600 text-white"
            onClick={onDelete}
          >
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

const TimeSheetHeader = ({
  title,
  date,
  totalWorkHours = 0,
  workStatus,
  openEntryInitialStartTime,
  onAdd,
  onClose,
  onChange,
}) => {
  const workTimeStamp = useLiveTimeCounter(
    new Date(),
    openEntryInitialStartTime,
  );

  return (
    <TimeTrackingHeader
      title={title}
      subtitle={`${totalWorkHours} work hours`}
      date={date}
      workStatus={workStatus}
      workTimeStamp={openEntryInitialStartTime ? workTimeStamp : null}
      onAdd={onAdd}
      onChange={onChange}
      onClose={onClose}
    />
  );
};

const TimeSheetHours = ({ children }) => {
  // array of hours 0 through 24
  const hours = Array.from(Array(24).keys()).map((hour) => {
    const date = new Date();
    date.setHours(hour);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  });

  const container = useRef(null);

  useEffect(() => {
    const scrollToNow = () => {
      // center now in the middle of the screen
      const now = new Date();
      const offset = now.getHours() * 60 + now.getMinutes();

      container.current.scrollTo({ top: offset });
    };

    scrollToNow();
  }, []);

  return (
    <div ref={container} className="relative h-[85vh] overflow-y-scroll pt-8">
      {hours.map((date) => {
        const label = format(date, "h a");
        return <HourBlock key={label} label={label} start={date} />;
      })}
      {children}
    </div>
  );
};

export const ViewYourDayButton = ({
  label = "View your day",
  className = "",
}) => {
  const navigate = useNavigate();

  const todayDateString = format(new Date(), "MM-dd-yy");

  return (
    <button
      className={cn("flex items-center justify-between", className)}
      onClick={() => navigate(`/timesheets/${todayDateString}`)}
    >
      <span className="flex items-center">
        <CalendarIcon className="mr-4" />
        <h5 className="text-lg font-medium">{label}</h5>
      </span>

      <span className="p-2">
        <RightArrow />
      </span>
    </button>
  );
};

export const TimeManagement = ({
  workStatus,
  timestamp,
  onClose,
  children,
}) => {
  return (
    <div className="fixed bottom-0 left-1/2 z-40 w-full max-w-2xl -translate-x-1/2">
      <div className="rounded-t-xl bg-zinc-800 shadow">
        <div
          className={cn("flex items-start justify-between rounded-t-xl p-4", {
            "bg-purple-slate": workStatus === utils.workingEnum.driving,
            "bg-emerald-800": workStatus === utils.workingEnum.working,
            "bg-zinc-700": workStatus === utils.workingEnum.break,
          })}
        >
          <div>
            <h5 className="text-lg">Time management</h5>

            {workStatus !== utils.workingEnum.break && (
              <>
                <span
                  className={cn("mr-2 text-sm font-medium", {
                    "text-indigo-300": workStatus === utils.workingEnum.driving,
                    "text-teal-400": workStatus === utils.workingEnum.working,
                  })}
                >
                  {utils.labels[workStatus]}
                </span>
                <span className="text-sm">{timestamp}</span>
              </>
            )}
          </div>
          <button className="p-2" onClick={onClose}>
            <XIcon className="w-4" />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default TimeSheetDayView;
