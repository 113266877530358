import React from "react";
import Tile from "./Tile";
import spa from "../../img/3d-spa.png";

const SpaTile = () => {
  return (
    <Tile name="Spa" square>
      <img src={spa} alt="Spa" className="p-2" />
    </Tile>
  );
};

export default SpaTile;
