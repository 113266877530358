export function hasDevices(room) {
  if (!room) throw new Error("Room is required");
  return (
    room.hasDimmer ||
    room.hasSwitch ||
    room.hasWindowCovering ||
    room.hasThermostat ||
    room.hasLock ||
    room.hasCourtesy ||
    room.hasMedia
  );
}

export function hasOnlyLock(room) {
  if (!room) throw new Error("Room is required");
  return (
    room.hasLock &&
    !room.hasDimmer &&
    !room.hasSwitch &&
    !room.hasWindowCovering &&
    !room.hasThermostat &&
    !room.hasCourtesy &&
    !room.hasMedia
  );
}

export function hasPaSystem(room) {
  if (!room) throw new Error("Room is required");
  if (!room.hasMedia) return false;
  const paSystem = room.mediaSources.find(
    (ms) => ms.discriminator === "paSystem",
  );
  return Boolean(paSystem);
}

export function hasAlarm(room) {
  if (!room) throw new Error("Room is required");
  return room.hasAlarm;
}

export function hasCamera(room) {
  if (!room) throw new Error("Room is required");
  return room.hasCamera;
}
