import { cn } from "@/lib/utils";
import { useAuth } from "hooks/use-auth";
import { useProperty } from "hooks/use-property";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import defaultProfile from "../../img/user-profile.png";
import DynamicResponsiveImage from "./DynamicResponsiveImage";
import StyledSelect from "./Forms/StyledSelect";

const StickyHeader = () => {
  const [smallHeader, setSmallHeader] = useState(false);
  const {
    data: currentUser,
    spaces,
    isCheckedIn,
    currentRole,
    checkedInReservations,
  } = useAuth();
  const { currentSpaceId, changeSpace } = spaces;
  const {
    property,
    isHospitality,
    currentPropertyId,
    changeProperty,
    properties,
  } = useProperty();

  const propertiesByName = properties?.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const isGuest = currentRole === "Guest";

  const selectableSpaces = spaces.data || [];
  // sort by name

  let userSpaces = [...selectableSpaces].sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  if (isHospitality && isCheckedIn && isGuest) {
    const globalCommonAccess =
      property?.appFeatures?.RoomControl?.commonAreas?.spaces;

    const checkedInSpaces = checkedInReservations.map((r) => r.space);

    userSpaces = userSpaces.filter((space) => {
      if (checkedInSpaces.includes(space.id)) return true;
      if (
        globalCommonAccess &&
        Array.isArray(globalCommonAccess) &&
        globalCommonAccess.length > 0
      ) {
        return !globalCommonAccess.includes(space.id);
      }

      return false;
    });
  }

  const handleScroll = useCallback(() => {
    const newState = window.scrollY > 0 ? true : false;
    if (smallHeader !== newState) {
      setSmallHeader(newState);
    }
  }, [smallHeader]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  let renderSpaceSelect = userSpaces?.length > 0 && isHospitality;

  if (isGuest && !isCheckedIn) renderSpaceSelect = false;

  return (
    <header
      className={cn(
        "container sticky top-0 mx-auto bg-dark-gray px-4 transition-all",
        {
          "py-8": !smallHeader,
          "py-2": smallHeader,
        },
      )}
    >
      <div className="flex items-center">
        <div className="flex-1 flex-grow">
          <p
            className={cn(
              "origin-top-left transform-gpu text-2xl font-medium transition-all",
              {
                "scale-100": !smallHeader,
                "scale-75": smallHeader,
              },
            )}
          >
            Hi {currentUser?.firstName ? currentUser.firstName : "there"}!
          </p>
          <p
            className={cn(
              "flex transform-gpu items-center gap-2 text-gray transition-all",
              {
                "-translate-y-2": smallHeader,
              },
            )}
          >
            {properties?.length > 1 ? (
              <PropertySelect
                properties={propertiesByName}
                selected={currentPropertyId}
                onChange={(propertyId) => changeProperty(propertyId)}
              />
            ) : (
              <span>{property?.name}</span>
            )}
            {renderSpaceSelect && (
              <>
                <Separator />
                <SpaceSelect
                  spaces={userSpaces}
                  onChange={(spaceId) => changeSpace(spaceId)}
                  selected={currentSpaceId}
                />
              </>
            )}
          </p>
        </div>
        <div className="flex flex-shrink items-center justify-end gap-x-1">
          <UserPhoto user={currentUser} />
        </div>
      </div>
    </header>
  );
};

const UserPhoto = ({ user }) => {
  return (
    <Link to="/users/me">
      {user?.photo ? (
        <DynamicResponsiveImage
          src={user.photo}
          mediaFile={user.photo}
          maxWidth={48}
          alt={user.fullName}
          className={"h-12 rounded-full"}
        />
      ) : (
        <img src={defaultProfile} alt="profile" className="h-12 rounded-full" />
      )}
    </Link>
  );
};

const SpaceSelect = ({ spaces = [], onChange, selected }) => {
  const options = spaces.map((space) => {
    return {
      id: space.id,
      name: space.name,
    };
  });

  return (
    <StyledSelect options={options} onChange={onChange} selected={selected} />
  );
};

const PropertySelect = ({ properties = [], onChange, selected }) => {
  const options = properties.map((property) => {
    return {
      id: property.id,
      name: property.name,
    };
  });

  return (
    <StyledSelect options={options} onChange={onChange} selected={selected} />
  );
};

const Separator = () => {
  return <span>|</span>;
};

export default StickyHeader;
