const { DEV, VITE_SW } = import.meta.env;

export function register() {
  return new Promise((resolve) => {
    if (DEV && !VITE_SW) return resolve();
    import("virtual:pwa-register")
      .then(({ registerSW }) => {
        console.log("registering service worker");
        const worker = registerSW({
          immediate: true,
          onRegisterError(error) {
            console.error(error);
            resolve();
          },
          onRegistered() {
            console.log("service worker registered");
            resolve();
          },
        });
        return { worker };
      })
      .catch(console.error);
  });
}
