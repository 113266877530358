import Router from "components/Router";
import { Toaster } from "components/ui/toaster";
import { ProvideAuth } from "hooks/use-auth";
import { ProvideFetch } from "hooks/use-fetch";
import { ProvideProperty } from "hooks/use-property";
import { ProvideScreenBlur } from "hooks/use-screenBlur";
import { ProvideSocketIo } from "hooks/use-socketIo";
import React from "react";
import ErrorBoundary from "./ErrorBoundary";

function App() {
  return (
    <React.StrictMode>
      <ErrorBoundary>
        <ProvideFetch>
          <ProvideProperty>
            <ProvideAuth>
              <ProvideSocketIo>
                <ProvideScreenBlur>
                  <Router />
                </ProvideScreenBlur>
              </ProvideSocketIo>
            </ProvideAuth>
          </ProvideProperty>
        </ProvideFetch>
        <Toaster />
      </ErrorBoundary>
    </React.StrictMode>
  );
}

export default App;
