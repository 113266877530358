import React from "react";
import LoadingSpinner from "../LoadingSpinner";

const SubmitButton = ({ label, onClick, loading, ...rest }) => {
  return (
    <button
      onClick={onClick}
      type="submit"
      className="flex w-full items-center justify-center rounded-sm bg-white px-1 py-3 font-medium text-black disabled:opacity-80"
      {...rest}
    >
      {label}
      {loading && (
        <LoadingSpinner
          width={16}
          height={16}
          className="ml-2 mt-0.5 text-black"
        />
      )}
    </button>
  );
};

export default SubmitButton;
