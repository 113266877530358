import React from "react";
import users from "../../img/3d-users-23.png";
import Tile from "./Tile";

const UsersTile = () => {
  return (
    <Tile name="Users" square link="/users">
      <img
        className="mx-auto h-full w-full object-contain"
        src={users}
        alt="Users"
      />
    </Tile>
  );
};

export default UsersTile;
