import React from "react";

const Star = ({ fill = "none" }) => (
  <svg
    fill={fill}
    height="15px"
    width="15px"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 490.655 490.655"
  >
    <path
      d="M490.102,188.328c-1.323-3.84-4.693-6.613-8.704-7.147l-154.496-20.48L254.88,16.317c-3.584-7.232-15.509-7.232-19.093,0
		   l-72.021,144.384L9.27,181.181c-4.011,0.533-7.403,3.307-8.704,7.147c-1.301,3.84-0.32,8.085,2.56,10.965l113.301,113.301
		   L85.558,466.984c-0.789,4.011,0.747,8.085,3.989,10.581c3.243,2.475,7.637,2.88,11.243,1.067l144.555-72.277l144.555,72.277
		   c1.515,0.747,3.136,1.131,4.779,1.131c2.283,0,4.587-0.747,6.485-2.197c3.221-2.475,4.779-6.571,3.989-10.581l-30.869-154.389
		   l113.301-113.301C490.4,196.434,491.382,192.189,490.102,188.328z"
    />
  </svg>
);

const HalfStar = ({ fill = "none" }) => (
  <div style={{ position: "relative" }}>
    <Star fill="#636363" />
    <div
      style={{
        position: "absolute",
        top: 0,
        overflow: "hidden",
        width: "50%",
        zIndex: 1,
      }}
    >
      <Star fill={fill} />
    </div>
  </div>
);

const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const halfStar =
    rating - fullStars >= 0.5 ? <HalfStar fill="#EEB953" /> : null;

  return (
    <div className="flex items-center justify-start gap-x-1">
      {Array(fullStars)
        .fill(null)
        .map((_, i) => (
          <Star key={i} fill="#EEB953" />
        ))}
      {halfStar}
      {Array(5 - Math.ceil(rating))
        .fill(null)
        .map((_, i) => (
          <Star key={i + fullStars} fill="#636363" />
        ))}
    </div>
  );
};

export default StarRating;
