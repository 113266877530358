import { differenceInCalendarDays, format } from "utils/dates";

export function isTicketAssignedToMe({ ticket, user }) {
  return Boolean(ticket.assignedTo?.userId === user?.id);
}

export function isTicketRequestedByMe({ ticket, user }) {
  return Boolean(ticket.requester?.userId === user?.id);
}

export function isTicketOpenedByMe({ ticket, user }) {
  return Boolean(ticket.openedBy?.userId === user?.id);
}

export function isTicketRequestedBySystem({ ticket }) {
  return Boolean(ticket.requester?.systemId);
}

export function getDepartment({ ticket }) {
  if (ticket.department) return ticket.department;
  return null;
}

export function getAssigneeToPhoto({ ticket }) {
  const assignedTo = ticket.assignedTo;
  if (assignedTo?.userPhoto) return assignedTo.userPhoto;
  return null;
}

export function getRequesterPhoto({ ticket }) {
  const requester = ticket.requester;
  if (ticket.requester?.userPhoto) return requester.userPhoto;
  if (ticket.requester?.systemPhoto) return requester.systemPhoto;
  return null;
}

export function getRequesterName({ ticket }) {
  const requester = ticket.requester;
  return requester.userName || requester.systemName;
}

export function getLastMessage({ ticket }) {
  const length = ticket.conversation.length;
  const index = length - 1;
  const lastMessage = ticket.conversation[index];
  return lastMessage;
}

export function getLastMessageUserName({ ticket }) {
  const lastMessage = getLastMessage({ ticket });
  return lastMessage?.userName || lastMessage?.systemName;
}

export function getLastMessageTimestamp({ ticket }) {
  const lastMessage = getLastMessage({ ticket });
  return lastMessage?.timestamp;
}

export function getScheduleDate({ ticket }) {
  if (!ticket.scheduleDate) return null;
  return new Date(ticket.scheduleDate);
}

export function getMessageTime(date) {
  return format(new Date(date), "h:mm a");
}

export function getMessageTimeLabel(date) {
  const now = new Date();
  const isToday = differenceInCalendarDays(now, new Date(date)) === 0;
  const isYesterday = differenceInCalendarDays(now, new Date(date)) === 1;
  if (isToday) return "Today";
  if (isYesterday) return "Yesterday";
  return format(new Date(date), "MMM dd");
}

export const ticketStatusOptions = [
  { value: "open", label: "📨 Open" },
  { value: "solved", label: "📩 Solved" },
  { value: "closed", label: "✉️ Closed" },
];

export const ticketPriorityOptions = [
  { value: "low", label: "Low" },
  { value: "normal", label: "Normal" },
  { value: "high", label: "High" },
];
