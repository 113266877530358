import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import React, { Component } from "react";

class ScrollLock extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
  }
  componentDidMount() {
    //window.scrollTo(0, 0);
    disableBodyScroll(this.node, {
      allowTouchMove: (el) => {
        while (el && el !== document.body) {
          // ignore data-body-scroll-lock-ignore attribute
          if (el.getAttribute("data-body-scroll-lock-ignore") === "true") {
            return true;
          }

          if (el.tagName === "INPUT") return true;

          el = el.parentElement;
        }
      },
    });
  }
  componentWillUnmount() {
    enableBodyScroll(this.node);
  }
  render() {
    return <div ref={(node) => (this.node = node)} className="hidden" />;
  }
}

export default ScrollLock;
