import { Client, Models as KohostModels, utils } from "@kohost/api-client";
import { getApiUrl } from "utils/getApiUrl";
import getOrganizationId from "utils/getOrganizationId";

const entityFactory = utils.entityFactory;

let organization = getOrganizationId();

if (organization === "auth") organization = null;

const apiUrl = getApiUrl();

const kohost = new Client({
  url: `${apiUrl}v3`,
  organizationId: organization,
  onSuccess: (response) => {
    if (Array.isArray(response.data)) {
      response.data = response.data.map((entryData) => {
        if (!entryData) return entryData;
        if (entryData.type) {
          try {
            const Entity = entityFactory(entryData.type);
            return new Entity(entryData);
          } catch (error) {
            console.error(error);
            if (error.cause) {
              console.error(error.cause);
            }
            return entryData;
          }
        }
        return entryData;
      });
    } else if (response.data?.type) {
      try {
        const Entity = entityFactory(response.data.type);
        response.data = new Entity(response.data);
      } catch (error) {
        console.error(error);
        if (error.cause) {
          console.error(error.cause);
        }
      }
    }

    return response;
  },
});

kohost.on("LoginRequired", () => {
  if (window.location.pathname !== "/login") {
    // if query string of pwaToken is present, redirect to /login?token={pwaToken}
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search);
      const token = params.get("pwaToken");
      if (token) {
        window.location.href = "/login?token=" + token;
      }
    } else {
      window.location.href = "/login";
    }
  }
});

kohost.on("PhoneVerificationRequired", () => {
  if (window.location.pathname !== "/verify-phone") {
    window.location.href = "/verify-phone";
  }
});

export const Models = KohostModels;

export default kohost;
