export function pickDevices(rooms, deviceType) {
  if (!rooms) return [];
  return rooms.reduce((acc, room) => {
    if (room[deviceType]?.length > 0) {
      const devices = room[deviceType].map((device) => {
        return { ...device, roomId: room.id };
      });
      return [...acc, ...devices];
    }

    return acc;
  }, []);
}

export function getRoomDevices(room, deviceType) {
  if (!room) return [];
  return pickDevices([room], deviceType);
}

export function isSceneActive(scene, room) {
  if (!scene || !room) return false;
  const lights = [
    ...getRoomDevices(room, "dimmers"),
    ...getRoomDevices(room, "switches"),
  ];
  switch (scene.name) {
    case "Room On": {
      return lights.every((l) => l.state === "on" || l.level === 100);
    }

    case "Room Off": {
      return lights.every((l) => l.state === "off" || l.level === 0);
    }

    case "Room Open": {
      const shades = getRoomDevices(room, "windowCoverings");
      return shades.every((s) => s.position === 0);
    }

    case "Room Close": {
      const shades = getRoomDevices(room, "windowCoverings");
      return shades.every((s) => s.position === 100);
    }
  }

  return false;
}

export function roomHasDeviceType(room, deviceType) {
  return room[deviceType]?.length > 0;
}

export function roomHasDeviceDiscriminator(room, discriminator) {
  const keys = Object.keys(room).filter((key) => Array.isArray(room[key]));
  let hasDiscriminator = false;
  keys.forEach((key) => {
    if (room[key].some((device) => device.discriminator === discriminator)) {
      hasDiscriminator = true;
    }
  });
  return hasDiscriminator;
}
