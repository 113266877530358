import React from "react";
import { Link } from "react-router-dom";
import { isAppleDevice } from "utils/browser";
import appIcon from "./images/roomkey-app-icon.png";

import { enums } from "utils/enums";

const LockAppBanner = ({
  title = "Room Key App",
  buttonTitle = "Open App",
  description = "",
}) => {
  const isApple = isAppleDevice();

  const appUrl = isApple ? enums.roomKeyIosAppUrl : enums.roomKeyAndroidAppUrl;

  const handleOpenApp = () => {
    try {
      window.open(appUrl, "_self");
    } catch (error) {
      //window.open(appUrl, "_blank");
    }
  };

  return (
    <div className="fixed left-0 top-0 z-40 w-full bg-dark-gray px-4 py-2">
      <div className="p">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="mr-2">
              <img src={appIcon} alt={title} className="w-12 rounded" />
            </div>
            <div>
              <p className="text-sm font-medium text-white">{title}</p>
              {description && (
                <p className="text-xs text-muted">{description}</p>
              )}
            </div>
          </div>
          <div>
            <Link
              as="button"
              onClick={handleOpenApp}
              className="rounded-3xl bg-blue px-2 py-1 text-sm font-medium uppercase text-white"
            >
              {buttonTitle}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockAppBanner;
