import React from "react";
import Tile from "./Tile";
import checkout from "../../img/3d-check-out.png";

const CheckOutTile = () => {
  return (
    <Tile name="Check Out" square link="/check-out">
      <img
        src={checkout}
        className="mx-auto h-full max-h-[75%] w-auto"
        alt="Check Out"
      />
    </Tile>
  );
};

export default CheckOutTile;
