import React from "react";

const TimeTrackingFooter = ({ children: content }) => {
  return (
    <div className="fixed bottom-0 left-1/2 mx-auto w-full max-w-2xl -translate-x-1/2 bg-dark-gray p-4 shadow-xl-top">
      <div className="flex flex-col justify-stretch gap-y-4 bg-dark-gray px-4">
        {content}
      </div>
    </div>
  );
};

export default TimeTrackingFooter;
