import React from "react";
import cams from "../../img/3d-camera-v2.png";
import Tile from "./Tile";

const CamerasTile = () => {
  return (
    <Tile name="Cameras" square link="/room-control/cameras">
      <img
        className="block h-full w-full object-contain"
        src={cams}
        alt="Cameras"
      />
    </Tile>
  );
};

export default CamerasTile;
