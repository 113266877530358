import React, { useRef } from "react";

import { useGSAP } from "@gsap/react";
import LockAppBanner from "components/DigitalKey/LockAppBanner";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";

import { cn } from "@/lib/utils";

import { useMeasure } from "@uidotdev/usehooks";
import { ReactComponent as Arrow } from "./icons/arrow.svg";
import { ReactComponent as CloseX } from "./icons/close.svg";
import { ReactComponent as LockedIcon } from "./icons/locked.svg";
import { ReactComponent as UnlockedIcon } from "./icons/unlocked.svg";

gsap.registerPlugin(Draggable);
gsap.registerPlugin(InertiaPlugin);

const LockPopup = ({
  locks = [],
  show,
  onClose,
  onSetLock,
  displayAppDownload = false,
}) => {
  const locksContainer = useRef(null);

  const { contextSafe } = useGSAP(
    () => {
      gsap.from('[data-lock-info="true"]', {
        height: 0,
        opacity: 0,
        duration: 0.3,
        ease: "power2.inOut",
      });

      gsap.fromTo(
        '[data-lock-slider="true"]',
        { width: "100px", right: 0, cssFloat: "right" },
        {
          width: "100%",
          duration: 0.3,
          ease: "power2.inOut",
        },
      );
    },
    { scope: locksContainer, dependencies: [show] },
  );

  const exit = contextSafe(() => {
    gsap.to('[data-lock-info="true"]', {
      height: 0,
      opacity: 0,
      duration: 0.3,
      ease: "power2.inOut",
    });

    gsap.to('[data-lock-slider="true"]', {
      width: "100px",
      right: 0,
      cssFloat: "right",
      duration: 0.25,
      ease: "power2.inOut",
      onComplete: () => onClose(),
    });
  });

  if (!show) return null;

  return (
    <>
      {displayAppDownload && show && (
        <LockAppBanner
          title="Room Key - Unlock Your Door"
          description="Get the Room Key app"
        />
      )}

      <div
        ref={locksContainer}
        data-body-scroll-lock-ignore={true}
        className={cn("fixed bottom-0 right-0 z-40 max-h-[90%] w-full", {
          "h-full": locks.length >= 3,
        })}
      >
        <div className="relative right-0 top-0 z-10">
          <ExitButton onClick={exit} />
        </div>
        <div className="h-full overflow-y-scroll bg-dark-gray p-4">
          <div className="origin-left overflow-y-scroll">
            {locks.map((data) => {
              const { id, roomId, ...lock } = data;
              return (
                <LockInterface
                  key={id}
                  id={id}
                  roomId={roomId}
                  lock={lock}
                  onSetLock={onSetLock}
                  onExit={exit}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

const LockInterface = ({ id, roomId, lock, onSetLock }) => {
  return (
    <div className="relative mb-8 only-of-type:mb-0">
      <LockInfo name={lock.name} state={lock.state} />
      <LockSlider
        key={lock.id}
        state={lock.state}
        onChange={(state) => onSetLock({ id, roomId, state })}
      />
    </div>
  );
};

const LockSlider = ({ state, onChange }) => {
  const lockInput = useRef(null);
  const [boundary, { width }] = useMeasure();

  const isUnlocked = state === "unlocked";
  const isLocked = state === "locked";

  useGSAP(
    (ctx) => {
      if (width === null) return;
      const inputWidth = lockInput.current.offsetWidth;
      const borderWidth = 8 * 2;
      const unlockValueX = width - borderWidth - inputWidth;

      const max = unlockValueX * 0.95;

      Draggable.create(lockInput.current, {
        type: "x",
        bounds: boundary.current,
        inertia: true,
        lockAxis: true,
        edgeResistance: 1,
        throwResistance: 1000,
        snap: {
          x: function (val) {
            if (val >= max) return unlockValueX;
            else return 0;
          },
        },
        onThrowComplete: function () {
          if (this.x >= max) this.target.style.marginLeft = "0";
          if (this.x === 0 && state !== "locked") onChange("locked");
          if (this.x === unlockValueX && state !== "unlocked")
            onChange("unlocked");
        },
      });
    },
    { scope: boundary, dependencies: [width], revertOnUpdate: true },
  );

  const center = "h-20 w-20 flex items-center justify-center";
  const iconColor = "#6a738a";

  return (
    <div
      ref={boundary}
      data-lock-slider="true"
      className="relative flex h-24 items-center overflow-hidden rounded-[48px] border-8 border-slate-600 bg-slate-600"
    >
      <button
        ref={lockInput}
        data-lock-input="true"
        className={cn(
          `${center} relative z-10 rounded-full shadow-md transition-colors`,
          isLocked ? "bg-slate-500" : "ml-auto bg-green",
        )}
      >
        {isLocked && <LockedIcon color="white" />}
        {isUnlocked && <UnlockedIcon />}
      </button>

      <div className="absolute left-0 top-0 flex h-full w-full items-center justify-between">
        <div className={center}>
          <LockedIcon color={iconColor} />
        </div>
        <div className={center}>
          <Arrow color={iconColor} />
        </div>
        <div className={center}>
          <UnlockedIcon color={iconColor} />
        </div>
      </div>
    </div>
  );
};

const LockInfo = ({ name = "Room Door", state = "locked" }) => {
  const nodeRef = useRef(null);

  return (
    <div ref={nodeRef} data-lock-info="true" className="relative mb-8">
      <h3 className="mb-2 line-clamp-1 text-center text-3xl font-medium">
        {name}
      </h3>
      <div className="text-center">
        <span
          className={cn(
            "rounded-2xl px-2 py-1 text-center uppercase transition duration-100",
            {
              "bg-green": state === "unlocked",
              "bg-slate-500": state === "locked",
            },
          )}
        >
          {state === "locked" ? "Locked" : "Unlocked"}
        </span>
      </div>
    </div>
  );
};

const ExitButton = ({ onClick }) => {
  return (
    <div className="absolute right-0 top-0 p-4 opacity-50">
      <button onClick={onClick} className="float-right">
        <CloseX className="float-right" color="#9AA1B0" />
      </button>
    </div>
  );
};

export default LockPopup;
