import { set } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

import { getHours, getMinutes } from "date-fns";
export {
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  format,
  formatDistance,
  isToday,
  parse,
  set,
  subDays,
} from "date-fns";

export function parseTime(string) {
  // convert 4:00 PM to 16:00
  const [time, modifier] = string.split(" ");
  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return {
    hours: parseInt(hours, 10),
    minutes: parseInt(minutes, 10),
  };
}

export function getNextDateUTC(
  start = new Date(),
  { hours = 0, minutes = 0, seconds = 0, milliseconds = 0, timezone },
) {
  const zonedDate = utcToZonedTime(start, timezone);

  return zonedTimeToUtc(
    set(zonedDate, {
      hours,
      minutes,
      seconds,
      milliseconds,
    }),
    timezone,
  );
}

export function midnightTodayAtTimezone(date, { timezone }) {
  let start = date;
  if (!start) start = new Date();
  if (timezone) start = utcToZonedTime(start, timezone);

  let midnight = set(start, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  if (timezone) midnight = zonedTimeToUtc(midnight, timezone);

  return midnight;
}

// return the time from the date in 24 hour format, add leading zeros if needed

export function get24HourTimeString(date) {
  if (!(date instanceof Date)) date = new Date(date);
  const hours = getHours(date);
  const minutes = getMinutes(date);
  return `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
}

export function parse24HourTimeString(string) {
  const [hours, minutes] = string.split(":");
  return {
    hours: parseInt(hours, 10),
    minutes: parseInt(minutes, 10),
  };
}

export function midnightAtTimezone(date, timezone) {
  if (typeof date !== "string") throw new Error("date must be a string");
  if (typeof timezone !== "string")
    throw new Error("timezone must be a string");
  const parts = date.split("-");
  const year = Number(parts[0]);
  // subtract 1 from month because date-fns uses 0-indexed months
  const month = Number(parts[1]) - 1;
  const day = Number(parts[2]);

  const zonedDate = utcToZonedTime(new Date(), timezone);

  const zoned = set(zonedDate, {
    year,
    month,
    date: day,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  return zonedTimeToUtc(zoned, timezone);
}
