export async function handleA2hs() {
  window.deferredPrompt.prompt();
  window.deferredPrompt.userChoice.then((choiceResult) => {
    if (choiceResult.outcome === "accepted") {
      //drop a cookie if they install
      let date = new Date();
      date.setTime(date.getTime() + 72 * 60 * 60 * 1000);
      let cookieExpires = "; expires=" + date.toGMTString();
      document.cookie = "pwaInstalled=1" + cookieExpires;
      return true;
    } else {
      return false;
    }
  });
}

const a2hs = {
  handleA2hs,
};

export default a2hs;
