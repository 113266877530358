import Button from "components/common/Button";
import DynamicResponsiveImage from "components/common/DynamicResponsiveImage";
import React from "react";
import { CSSTransition } from "react-transition-group";

import defaultPhoto from "../../img/user-profile.png";

const CurrentUserConfirmation = ({
  show,
  user = {},
  onConfirm,
  onClose,
  autoConfirm,
}) => {
  const nodeRef = React.useRef(null);

  React.useEffect(() => {
    if (autoConfirm) {
      onConfirm();
    }
  }, [autoConfirm, onConfirm]);

  if (autoConfirm) return null;
  return (
    <CSSTransition
      in={show}
      mountOnEnter
      unmountOnExit
      timeout={500}
      nodeRef={nodeRef}
      classNames={{
        enter: "animate-slideFromBottom",
        enterActive: "animate-slideFromBottom",
        exit: "animate-slideToBottom",
        exitActive: "animate-slideToBottom",
      }}
    >
      <div ref={nodeRef} className="fixed bottom-0 left-0 right-0 z-40 w-full">
        <div className="mx-auto max-w-xl rounded-t-md bg-[#27292F] px-4 pb-4">
          <div className="cursor-grab p-4 active:cursor-grabbing">
            <h5 className="text-center text-lg font-medium">Log In?</h5>
          </div>

          <div className="mb-4 text-center">
            {user.photo ? (
              <DynamicResponsiveImage
                className="mx-auto mb-2 block h-12 w-12 rounded-full"
                maxWidth={32}
                mediaFile={user.photo}
                alt={user.fullName}
              />
            ) : (
              <img
                className="mx-auto mb-1 block h-12 w-12 rounded-full"
                src={defaultPhoto}
              />
            )}

            <h3 className="text-lg">{user.fullName}</h3>
          </div>

          <div className="flex w-full flex-col gap-y-4">
            <Button
              className="flex-1 border-green bg-green text-white"
              onClick={onConfirm}
            >
              Continue as {user.fullName}
            </Button>
            <Button className="flex-1" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default CurrentUserConfirmation;
