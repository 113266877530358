import { useState } from "react";

function parse(item) {
  try {
    return JSON.parse(item);
  } catch (error) {
    return item;
  }
}

// Hook
export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    // Get from local storage by key
    const item = localStorage.getItem(key);
    // Parse stored json or if none return initialValue
    return item ? parse(item) : initialValue;
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      let valueToStore = value instanceof Function ? value(storedValue) : value;

      if (valueToStore === null || valueToStore === undefined) return;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof valueToStore !== "string")
        valueToStore = JSON.stringify(valueToStore);
      localStorage.setItem(key, valueToStore);
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}
