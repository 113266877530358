import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";

const FadeTransition = ({ show, children }) => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition
      in={Boolean(show)}
      timeout={300}
      nodeRef={nodeRef}
      classNames={{
        enterActive: "animate-fadeIn",
        exitActive: "animate-fadeOut",
      }}
      unmountOnExit
      mountOnEnter
    >
      <div ref={nodeRef}>{children}</div>
    </CSSTransition>
  );
};

export default FadeTransition;
