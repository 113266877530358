import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

/**
 * @typedef {Object} SkeletonProps
 * @property {string} [baseColor] - The base color of the skeleton.
 * @property {string} [highlightColor] - The highlight color of the skeleton.
 * @property {number} [duration] - The duration of the animation in seconds.
 * @property {string} [width] - The width of the skeleton.
 * @property {string} [height] - The height of the skeleton.
 * @property {boolean} [circle] - Whether the skeleton should be circular.
 * @property {boolean} [count] - The number of skeletons to render.
 */

/**
 * @type {React.FC<SkeletonProps>}
 */
const SkeletonLoader = (props) => {
  return <Skeleton baseColor="#222429" highlightColor="#1d1f22" {...props} />;
};

export default SkeletonLoader;
