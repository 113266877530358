import React from "react";
import Tile from "./Tile";
import store from "../../img/3d-store.png";

const StoreTile = () => {
  return (
    <Tile name="Shop" square>
      <img src={store} alt="Shop" />
    </Tile>
  );
};

export default StoreTile;
