import React from "react";
import Tile from "./Tile";
import myBuilding from "../../img/3d-building.png";
import { useProperty } from "hooks/use-property";

const MyBuildingTile = () => {
  const { isHospitality } = useProperty();
  const title = isHospitality ? "My Hotel" : "My Building";
  return (
    <Tile name={title} link="/room-control">
      <img
        src={myBuilding}
        alt={title}
        className="mx-auto max-h-48 max-w-[85%]"
      />
    </Tile>
  );
};

export default MyBuildingTile;
