import React from "react";
import Tile from "./Tile";
import dining from "../../img/3d-food.png";
import { useProperty } from "hooks/use-property";
import { useAuth } from "hooks/use-auth";

const DiningTile = () => {
  const { property } = useProperty();
  const { data: currentUser } = useAuth();
  const Dining = property?.appFeatures?.Dining;
  const diningConfig = Dining?.systemConfig;
  const diningSystem = Dining?.system;

  let link = null;

  if (diningSystem && diningConfig?.landingUrl) {
    const url = new URL(diningConfig.landingUrl);

    if (diningSystem === "gotab" && Boolean(diningConfig.includeUserDetails)) {
      if (currentUser.firstName)
        url.searchParams.append("fname", currentUser?.firstName);
      if (currentUser.lastName)
        url.searchParams.append("lname", currentUser?.lastName);
      if (currentUser?.email)
        url.searchParams.append("email", currentUser?.email);
      if (currentUser?.phone) {
        // remove the starting "+"
        const phone = currentUser.phone.replace(/^\+/, "");
        url.searchParams.append("phone", phone);
      }
    }

    link = url.href;
  }

  return (
    <Tile name="Dining" square link={link} target={link ? "_blank" : "_self"}>
      <img src={dining} alt="Dining" />
    </Tile>
  );
};

export default DiningTile;
