import { cn } from "@/lib/utils";
import React from "react";
import Button from "../Button";

const Modal = ({ children: content }) => {
  return (
    <div
      className="relative z-50 animate-fadeIn"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* Background backdrop, show/hide based on modal state. */}
      {/* Entering: "ease-out duration-300" */}
      {/* From: "opacity-0" */}
      {/* To: "opacity-100" */}
      {/* Leaving: "ease-in duration-200" */}
      {/* From: "opacity-100" */}
      {/* To: "opacity-0" */}
      <div className="fixed inset-0 bg-dark-gray bg-opacity-90 blur-md transition-opacity"></div>

      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          {/* Modal panel, show/hide based on modal state. */}
          {/* Entering: "ease-out duration-300" */}
          {/* From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" */}
          {/* To: "opacity-100 translate-y-0 sm:scale-100" */}
          {/* Leaving: "ease-in duration-200" */}
          {/* From: "opacity-100 translate-y-0 sm:scale-100" */}
          {/* To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" */}
          <div className="relative w-full transform rounded-lg bg-dark-gray text-left shadow-xl transition-all sm:my-8 sm:max-w-lg lg:max-w-2xl xl:max-w-3xl">
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalBody = ({ children: content }) => {
  return (
    <div className="bg-dark-gray px-4 pb-4 pt-5 sm:p-6 sm:pb-4">{content}</div>
  );
};

const ModalFooter = ({ children: content }) => {
  return (
    <div className="flex flex-col gap-3 bg-[#1a1b1d] px-4 py-3 sm:flex-row-reverse sm:px-6">
      {content}
    </div>
  );
};

const ModalButton = ({ children: content, className = "", ...rest }) => {
  const hasBg = className.includes("bg-");
  const hasBorder = className.includes("border-");
  const hasText = className.includes("text-");

  const bgCx = hasBg ? "" : "bg-white";
  const borderCx = hasBorder ? "" : "border-white";
  const textCx = hasText ? "" : "text-dark-gray";

  return (
    <Button
      type="button"
      className={cn(
        "inline-flex w-full justify-center px-3 py-2 text-sm sm:mt-0 sm:w-auto",
        bgCx,
        borderCx,
        textCx,
        className,
      )}
      {...rest}
    >
      {content}
    </Button>
  );
};

Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
Modal.Button = ModalButton;

const Icons = {
  Plus: () => (
    <svg
      className="h-6 w-6 text-white"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
      />
    </svg>
  ),
  Warning: () => (
    <svg
      className="h-6 w-6 text-red-600"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
      />
    </svg>
  ),
  Pencil: () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
      <path
        fill="currentColor"
        d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"
      />
    </svg>
  ),
};

Modal.Icons = Icons;

export default Modal;
