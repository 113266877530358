import React from "react";
import bullhorn from "../../img/3d-pa-system.png";
import Tile from "./Tile";

const PaSystemTile = () => {
  return (
    <Tile name="PA System" square link="/room-control/pa">
      <img
        className="mx-auto h-full w-full object-contain"
        src={bullhorn}
        alt="PA System"
      />
    </Tile>
  );
};

export default PaSystemTile;
