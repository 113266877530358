export const toSnakeCase = (str) => {
  return str.replace(/([A-Z])/g, "_$1").toLowerCase();
};

export const getJwtPayload = (tokenString) => {
  if (!tokenString) return null;
  try {
    const base64Url = tokenString.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  } catch (error) {
    // invalid jwt
    return null;
  }
};
