const roles = {
  SuperAdmin: "SuperAdmin",
  Admin: "Administrator",
  Manager: "Manager",
  Maintenance: "Maintenance",
  User: "User",
  Guest: "Guest",
};
export const canUpdateTickets = (user) => {
  const validRoles = [roles.SuperAdmin, roles.Admin, roles.Manager, roles.User];
  let canUpdate = false;
  if (user && user.roles) {
    user.roles.forEach((role) => {
      if (validRoles.includes(role)) {
        canUpdate = true;
      }
    });
  }

  return canUpdate;
};

export const roleCanUpdateTickets = (role) => {
  const validRoles = [roles.SuperAdmin, roles.Admin, roles.Manager];
  return validRoles.includes(role);
};

export const roleCanAssignTickets = (role) => {
  const validRoles = [roles.SuperAdmin, roles.Admin, roles.Manager];
  return validRoles.includes(role);
};

export const sortRoles = (a, b) => {
  const validRoles = Object.values(roles);
  return validRoles.indexOf(a) - validRoles.indexOf(b);
};

export const hasRoleGreaterThan = (user, role) => {
  const validRoles = Object.values(roles);
  const userRoles = user?.roles || [];

  let hasGreaterThan = false;

  userRoles.forEach((userRole) => {
    if (validRoles.indexOf(userRole) < validRoles.indexOf(role)) {
      hasGreaterThan = true;
    }
  });

  return hasGreaterThan;
};

export const isRoleGreaterThan = (role, otherRole) => {
  const validRoles = Object.values(roles);
  return validRoles.indexOf(role) < validRoles.indexOf(otherRole);
};

export const hasOnlyRole = (user, role) => {
  const userRoles = user?.roles || [];
  if (userRoles.length > 1) return false;
  else return userRoles[0] === role;
};

export const getUserOrganizationPermissions = (user, organizationId) => {
  return user.permissions.filter((p) => {
    if (p.organizationId === "*") return p;
    return p.organizationId === organizationId;
  });
};

export const getUserPropertyPermissions = (
  user,
  organizationId,
  propertyId,
) => {
  if (!user) return [];
  const orgPermissions = getUserOrganizationPermissions(user, organizationId);

  const propertyPermissions = orgPermissions.filter((p) => {
    if (p.propertyId === "*") return p;
    return p.propertyId === propertyId;
  });

  return propertyPermissions;
};

export const getRolesFromPermissions = (permissions = []) => {
  const roles = new Set();
  permissions.forEach((p) => {
    roles.add(p.role);
  });

  return Array.from(roles);
};
