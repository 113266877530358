import React from "react";
import sos from "../../img/3d-sos.png";
import Tile from "./Tile";

const PaSystemTile = () => {
  return (
    <Tile name="SOS" square link="/sos">
      <img
        className="mx-auto h-full w-full object-contain"
        src={sos}
        alt="SOS"
      />
    </Tile>
  );
};

export default PaSystemTile;
