import React from "react";
import * as Sentry from "@sentry/react";
import "./services/sentry";
import { useRouteError } from "react-router-dom";

export const ErrorMessage = () => {
  const error = useRouteError();
  console.error(error);
  return (
    <div className="container mx-auto flex h-screen flex-col justify-center px-4 h-screen-ios">
      <div className="mx-auto flex h-full max-w-2xl flex-col items-center justify-center text-center">
        <h1 className="mb-2 text-2xl font-medium">
          Oops! Something went wrong here.
        </h1>
        <p className="mb-4 text-lg">
          Our team has been notified so that we can get this fixed. If the error
          persists, please try again later.
        </p>
        <button
          onClick={() => {
            window.location.href = "/";
          }}
          className="block rounded-sm bg-green px-8 py-2 text-white"
        >
          Back Home
        </button>
      </div>
    </div>
  );
};

const ErrorBoundary = ({ children, fallback: Fallback }) => {
  return (
    <Sentry.ErrorBoundary fallback={Fallback ? <Fallback /> : ErrorMessage}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
