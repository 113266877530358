import { cn } from "@/lib/utils";
import React from "react";
import InputMask from "react-input-mask";
import { ReactComponent as CheckMark } from "./check-mark.svg";

const Input = React.forwardRef(
  (
    {
      name,
      label,
      onChange,
      value = "",
      valid,
      mask,
      className,
      as = "input",
      ...rest
    },
    ref,
  ) => {
    const isEmpty = value === "";

    const inputStyles = cn(
      "bg-[#24262c] rounded-sm text-sm text-white pb-3 px-4 block w-full peer/input leading-loose focus:outline-pink border-0 focus:border-0",
      {
        "is-not-empty": !isEmpty,
        "is-empty": isEmpty,
        "pt-5": label && as === "input",
        "pt-6": label && as === "textarea",
        "pt-3": !label,
      },
      className,
    );

    const InputComponent = mask ? InputMask : as;

    const props = {
      id: name,
      name: name,
      onChange: onChange,
      value: value,
      className: inputStyles,
      ...rest,
    };

    if (mask) props.mask = mask;

    const group = as === "textarea" ? "group isTextarea" : "group isInput";

    return (
      <label htmlFor={name} className={`relative block w-full ${group}`}>
        <InputComponent ref={ref} {...props} />
        {label && <InputLabel label={label} />}
        <Validity valid={valid} />
      </label>
    );
  },
);

Input.displayName = "Input";

const InputLabel = ({ label }) => {
  const labelStyles = cn(
    "text-[#b1b9c8] absolute left-4 group-[.isTextarea]:top-4 group-[.isInput]:top-1/2 group-[.isInput]:-translate-y-1/2 motion-safe:transition-all ease-in-out",
    "peer-focus/input:top-3.5 peer-focus/input:text-xs peer-[.is-not-empty]/input:text-xs peer-[.is-not-empty]/input:top-3.5",
  );
  return <span className={labelStyles}>{label}</span>;
};

const Validity = ({ valid = false }) => {
  const validityStyles = cn(
    "absolute top-0 right-0 h-full mr-4 flex items-center justify-center ",
    valid ? "opacity-100" : "opacity-0",
  );
  return (
    <span className={validityStyles}>
      <CheckMark width="20px" height="20px" color="#44B078" />
    </span>
  );
};

export default Input;
