import { midnightTodayAtTimezone } from "utils/dates";
import { useAuth } from "./use-auth";
import * as utils from "components/Concierge/TimeTracking/TimeTracking.utils";
import { useProperty } from "./use-property";

export const useTimeTracking = (timesheetData) => {
  const { data: currentUser } = useAuth();
  const { property } = useProperty();
  const timezone = property?.timezone;

  const {
    data: timeSheet,
    createTimeSheet: createTimeSheetMutation,
    createTimeSheetTimeEntry: createTimeSheetTimeEntryMutation,
    updateTimeSheetTimeEntry: updateTimeSheetTimeEntryMutation,
    deleteTimeSheetTimeEntry: deleteTimeSheetTimeEntryMutation,
  } = timesheetData;

  const tsId = timeSheet ? timeSheet.id : null;
  const tsDay = timeSheet
    ? new Date(timeSheet.day)
    : midnightTodayAtTimezone(new Date(), { timezone: timezone });

  const entries = timeSheet?.timeEntries || [];

  const openEntry = entries.find((e) => !e.end);
  const openEntryId = openEntry?.id;

  const createTimeSheet = createTimeSheetMutation.mutateAsync;
  const createTimeSheetTimeEntry = createTimeSheetTimeEntryMutation.mutateAsync;
  const updateTimeSheetTimeEntry = updateTimeSheetTimeEntryMutation.mutateAsync;
  const deleteTimeSheetTimeEntry = deleteTimeSheetTimeEntryMutation.mutateAsync;

  const handleChangeEntries = async (entry) => {
    if (!tsId) {
      await createTimeSheet({
        day: tsDay,
        userId: currentUser.id,
        timeEntries: [entry],
      });
    } else {
      if (entry.id) {
        const existingEntry = entries.find((e) => e.id === entry.id);
        if (existingEntry) {
          const updatedEntry = { ...existingEntry, ...entry };
          await updateTimeSheetTimeEntry({
            id: updatedEntry.id,
            timeSheetId: tsId,
            ...updatedEntry,
          });
        }
      } else {
        await createTimeSheetTimeEntry({ timeSheetId: tsId, ...entry });
      }
    }
  };

  const handleDeleteEntries = (entry) => {
    if (entry.id) {
      deleteTimeSheetTimeEntry({ id: entry.id, timeSheetId: tsId });
    }
  };

  const startDriving = (ticketId) => {
    const now = new Date();

    const newEntry = {
      discriminator: utils.workingEnum.driving,
      start: now.toISOString(),
    };

    if (ticketId) {
      newEntry.ticketId = ticketId;
    }

    return handleChangeEntries(newEntry);
  };

  const stopDriving = () => {
    const now = new Date();
    const entry = {
      id: openEntryId,
      end: now.toISOString(),
    };
    return handleChangeEntries(entry);
  };

  const startWorking = (ticketId) => {
    const now = new Date();
    const newEntry = {
      discriminator: utils.workingEnum.working,
      start: now.toISOString(),
    };

    if (ticketId) {
      newEntry.ticketId = ticketId;
    }

    return handleChangeEntries(newEntry);
  };

  const stopWorking = () => {
    const now = new Date();
    const entry = {
      id: openEntryId,
      end: now.toISOString(),
    };
    return handleChangeEntries(entry);
  };

  const startBreak = () => {
    const now = new Date();
    const newEntry = {
      discriminator: utils.workingEnum.break,
      start: now.toISOString(),
    };

    return handleChangeEntries(newEntry);
  };

  const stopBreak = () => {
    const now = new Date();
    const entry = {
      id: openEntryId,
      end: now.toISOString(),
    };
    return handleChangeEntries(entry);
  };

  return {
    changeEntry: handleChangeEntries,
    deleteEntry: handleDeleteEntries,
    startDriving,
    stopDriving,
    startWorking,
    stopWorking,
    startBreak,
    stopBreak,
  };
};
