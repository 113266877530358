import React from "react";
import Tile from "./Tile";
import mySuite from "../../img/3d-hotelroom.png";

const MySuiteTile = () => {
  return (
    <Tile name="My Suite" link="/room-control">
      <img
        src={mySuite}
        alt="My Suite"
        className="mx-auto max-h-48 max-w-[85%]"
      />
    </Tile>
  );
};

export default MySuiteTile;
