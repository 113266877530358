import getOrganizationId, { demoOrgs } from "utils/getOrganizationId";

const { VITE_MSW } = import.meta.env;

const orgId = getOrganizationId();

export async function register() {
  const isDemo = demoOrgs.includes(orgId);
  if (VITE_MSW || isDemo) {
    return import("./mocks/browser").then(({ worker }) => {
      // if this is a demo, dynamically import the handlers from "./handlers-${orgId}.js"
      // otherwise, import the default handlers from "./handlers.js"

      return import(`./mocks/handlers`).then(
        async ({ handlers, getMockData }) => {
          await getMockData(orgId);
          await worker.start({
            onUnhandledRequest: "bypass",
          });
          worker.use(...handlers);
          return { worker };
        },
      );
    });
  }

  return { worker: null };
}
