import React from "react";
import { getTimeSince } from "utils/messaging";

export const useLiveTimeCounter = (start = new Date(), end = null) => {
  const [time, setTime] = React.useState(getTimeSince(start, end));

  React.useEffect(() => {
    let interval;
    if (end) {
      interval = setInterval(() => {
        setTime(getTimeSince(start, end));
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [start, end]);

  return time;
};
