import { cn } from "@/lib/utils";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import LoadingSpinner from "components/common/LoadingSpinner";
import LoginForm from "./LoginForm";
import Logo from "./Logo";
import Splash from "./Splash";

import { useAuth } from "hooks/use-auth";
import { useProperty } from "hooks/use-property";

import { getJwtPayload } from "utils/string";

import myKeyIcon from "img/key-app-icon.png";
import CurrentUserConfirmation from "./CurrentUserConfirmation";

const redirectQueryKey = "redirect_uri";
const intentQueryKey = "intent";
const tokenQueryKey = "token";
const orgIdQuery = "organizationId";

const Login = () => {
  const {
    startLogin,
    finishLogin,
    getToken,
    registerPasskey,
    getSavedPasskeys,
    data: currentUser,
  } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  const loginToken = searchParams.get(tokenQueryKey);
  const intent = searchParams.get(intentQueryKey);
  const redirectUri = searchParams.get(redirectQueryKey);

  const redirectToSameOrigin = redirectUri?.includes(window.location.origin);

  const reducedMotion = window.matchMedia(
    "(prefers-reduced-motion: reduce)",
  ).matches;

  const [loading, setLoading] = React.useState(loginToken !== null);
  const [logoLoaded, setLogoLoaded] = React.useState(false);
  const [splashLoaded, setSplashLoaded] = React.useState(false);
  const [animateComplete, setAnimateComplete] = React.useState(
    reducedMotion || intent === "digitalKey",
  );
  const [showCurrentUserConfirmation, setShowCurrentUserConfirmation] =
    React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  useEffect(() => {
    if (currentUser?.id && animateComplete && !loginToken) {
      setShowCurrentUserConfirmation(true);
    }
  }, [currentUser?.id, animateComplete, loginToken]);

  const { organization, organizationId } = useProperty();

  const loginForm = useRef(null);
  const splash = useRef(null);
  const logo = useRef(null);
  const container = useRef(null);
  const loader = useRef(null);

  const appManifest = organization?.appManifest;

  let logoSrc = intent === "digitalKey" ? myKeyIcon : appManifest?.logo?.src;
  if (!logoSrc) logoSrc = myKeyIcon;
  const splashSrc = appManifest?.splash?.src;

  const {
    mutateAsync: handleFinishLogin,
    isPending: isFinishingLogin,
    isSuccess: finishLoginSuccess,
    isError: finishLoginError,
    reset: resetFinishLogin,
  } = finishLogin;
  const { mutateAsync: handleGetToken } = getToken;
  const { mutateAsync: handleRegisterPasskey } = registerPasskey;

  const imagesLoaded = logoLoaded && splashLoaded;

  useGSAP(
    () => {
      const tl = gsap.timeline({ repeat: 0 }).pause();

      const showLoader = () => {
        gsap.set(loader.current, { opacity: 1, display: "block" });
      };
      const animate = () => {
        const timelineRunning = tl.isActive();

        if (!timelineRunning) {
          gsap.set(loader.current, { opacity: 0, display: "none" });
          tl.play();
          const splashWidth = gsap.getProperty(splash.current, "width");
          const splashWiderThanViewport = splashWidth * 0.5 > window.innerWidth;

          const logoWidth = gsap.getProperty(logo.current, "width");
          const containerWidth = gsap.getProperty(container.current, "width");
          gsap.set(loginForm.current, {
            opacity: 0,
          });

          gsap.set(splash.current, {
            opacity: 1,
            position: "absolute",
          });

          gsap.set(logo.current, {
            opacity: 1,
            translateX: containerWidth / 2 - logoWidth / 2,
            translateY: 75 * 1.5 - 16,
            top: "-1rem",
          });

          tl.fromTo(
            splash.current,
            { opacity: 0 },
            { opacity: 1, duration: 1 },
          );

          tl.to(splash.current, {
            left: splashWiderThanViewport ? splashWidth * -0.5 : "0",
            ease: "Linear.easeNone",
            duration: splashWiderThanViewport ? 5 : 1,
          });

          tl.fromTo(
            splash.current,
            { opacity: 1 },
            { opacity: 0, pointerEvents: "none", duration: 0.75 },
          );

          tl.to(
            loginForm.current,
            {
              opacity: 1,
              position: "relative",
              zIndex: 10,
              display: "block",
              duration: 0.75,
            },
            "-=0.75",
          );

          tl.fromTo(
            logo.current,
            { opacity: 1, margin: 0 },
            {
              duration: 0.5,
              translateX: 0,
              translateY: 0,
              left: 0,
              top: 0,
              ease: "linear.ease",
            },
            "-=1.25",
          );

          tl.fromTo(
            loginForm.current,
            { opacity: 0, y: "200%" },
            {
              y: 0,
              opacity: 1,
              duration: 0.5,
              ease: true,
              onComplete: () => setAnimateComplete(true),
            },
            "-=1.25",
          );
        }

        function forwardAnimation() {
          const totalTime = tl.totalDuration();
          const currentTime = tl.totalTime();
          if (currentTime < totalTime) {
            tl.seek("5.5", false);
            container.current.removeEventListener("click", forwardAnimation);
            setAnimateComplete(true);
            return;
          }
        }

        container.current?.addEventListener("click", forwardAnimation);
      };
      if (
        !reducedMotion &&
        !loading &&
        imagesLoaded &&
        intent !== "digitalKey"
      ) {
        animate();
      }

      if (!imagesLoaded && intent !== "digitalKey") {
        showLoader();
      }
    },
    {
      dependencies: [loading, reducedMotion, intent, imagesLoaded],
    },
  );

  useEffect(() => {
    const autoLoginWithToken = async () => {
      try {
        const loginPayload = getJwtPayload(loginToken);
        await handleFinishLogin(
          {
            data: { ...loginPayload, token: loginToken },
          },
          {
            onError: () => {
              setErrorMessage("Session expired, please login again.");
              setLoading(false);
              setSearchParams((params) => {
                params.delete(tokenQueryKey);
                return params;
              });
            },
            onSuccess: async () => {
              try {
                const hasPasskeys = await getSavedPasskeys();
                if (hasPasskeys?.length === 0) {
                  await handleRegisterPasskey();
                }
              } catch (error) {
                console.error(error);
              }

              window.open(redirectUri || "/", "_self");
            },
          },
        );
      } catch (error) {
        setLoading(false);
      }
    };

    if (
      loginToken &&
      !isFinishingLogin &&
      !finishLoginSuccess &&
      !finishLoginError
    ) {
      autoLoginWithToken();
    }
  }, [
    loginToken,
    handleFinishLogin,
    isFinishingLogin,
    finishLoginSuccess,
    handleRegisterPasskey,
    getSavedPasskeys,
    redirectUri,
    finishLoginError,
    setSearchParams,
  ]);

  const handleSuccess = async () => {
    try {
      setLoading(true);
      resetFinishLogin();

      const { token } = await handleGetToken();

      setSearchParams((params) => {
        if (!redirectUri) {
          params.set(redirectQueryKey, window.location.origin);
          params.set(tokenQueryKey, token);
        } else if (!redirectToSameOrigin) {
          let redirectUriWithToken = `${redirectUri}?${tokenQueryKey}=${token}`;

          if (organizationId)
            redirectUriWithToken += `&${orgIdQuery}=${organizationId}`;
          else {
            // get the organization from the token payload
            const orgId = getJwtPayload(token)?.organization;
            if (orgId) redirectUriWithToken += `&${orgIdQuery}=${orgId}`;
          }
          params.set(redirectQueryKey, redirectUriWithToken);
        }

        return params;
      });
    } catch (error) {
      setErrorMessage("");
      setLoading(false);
    }
  };

  const queryOrg = searchParams.get(orgIdQuery);

  useEffect(() => {
    if (!redirectToSameOrigin && redirectUri?.includes(tokenQueryKey)) {
      try {
        if (!redirectUri.includes(orgIdQuery) && queryOrg) {
          const withOrgId = `${redirectUri}&${orgIdQuery}=${queryOrg}`;
          window.open(withOrgId, "_self");
        } else {
          window.open(redirectUri, "_self");
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [redirectToSameOrigin, redirectUri, queryOrg]);

  if (loading) return <LoadingSpinner fullScreen height={36} width={36} />;

  return (
    <>
      <div
        className={cn(
          "relative h-screen overflow-x-hidden transition-opacity duration-100 h-screen-ios",
          {
            "opacity-0": !logoLoaded && !splashLoaded,
          },
        )}
      >
        <div
          ref={container}
          className="relative mx-8 flex h-screen max-w-2xl flex-col justify-center transition-all h-screen-ios md:mx-auto"
        >
          <div className="mb-4 text-left">
            <Logo ref={logo} onLoad={() => setLogoLoaded(true)} src={logoSrc} />
          </div>

          <LoginForm
            ref={loginForm}
            onLoginSuccess={handleSuccess}
            startLogin={startLogin.mutateAsync}
            finishLogin={finishLogin.mutateAsync}
            errorMessage={errorMessage}
            onError={setErrorMessage}
            successMessage={successMessage}
            onSuccess={setSuccessMessage}
          />
        </div>
        <Splash
          onLoad={() => setSplashLoaded(true)}
          ref={splash}
          src={splashSrc}
        />
      </div>
      <CurrentUserConfirmation
        show={showCurrentUserConfirmation}
        user={currentUser}
        onClose={() => setShowCurrentUserConfirmation(false)}
        onConfirm={handleSuccess}
        autoConfirm={Boolean(
          showCurrentUserConfirmation && intent === "digitalKey",
        )}
      />
      <div ref={loader} className="fixed inset-0 hidden bg-dark-gray opacity-0">
        <LoadingSpinner fullScreen height={36} width={36} />
      </div>
    </>
  );
};

export default Login;
