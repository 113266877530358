import React, { useEffect } from "react";
import { ReactComponent as DownArrow } from "./arrow.svg";

const StyledSelect = ({ onChange, selected = "", options = [] }) => {
  const handleChange = ({ target }) => {
    const id = target?.value;
    onChange(id);
  };

  const selectedOption = options.find((opt) => opt.id === selected);

  useEffect(() => {
    if (!selected && options.length > 0) {
      const newSelected = options[0].id;
      onChange(newSelected);
    }

    if (selected && !selectedOption && options.length > 0) {
      const newSelected = options[0].id;
      onChange(newSelected);
    }
  }, [options, selected, onChange, selectedOption]);

  return (
    <>
      <span className="relative inline-flex items-center overflow-hidden">
        <select
          className="absolute z-10 inline w-auto appearance-none border-none bg-transparent leading-tight text-gray opacity-0 focus:outline-none"
          onChange={handleChange}
          value={selected}
          disabled={options.length === 1}
        >
          {options.map((opt) => {
            return (
              <option key={opt.id} value={opt.id}>
                {opt.name}
              </option>
            );
          })}
        </select>
        {selectedOption && selectedOption.name}
        {options.length > 1 && (
          <DownArrow className="ml-2 inline h-1.5 w-2 text-gray" />
        )}
      </span>
    </>
  );
};

export default StyledSelect;
