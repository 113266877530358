export const workingEnum = {
  working: "working",
  driving: "driving",
  break: "break",
};

export const labels = {
  working: "Working",
  driving: "Driving",
  break: "Break",
};

export function getTotalWorkHours(timeEntries, roundDecimal = 1) {
  const totalWorkHours = timeEntries
    .filter((entry) => entry.end && entry.discriminator === workingEnum.working)
    .reduce((acc, curr) => {
      const { start, end } = curr;
      const hours = getTimeDistance(start, end);
      return acc + hours;
    }, 0);

  if (totalWorkHours === 0) return 0;

  return totalWorkHours.toFixed(roundDecimal);
}

export function getTimeDistance(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diff = endDate - startDate;
  const hours = diff / 1000 / 60 / 60;
  return hours;
}

export function getWorkStatus(entry) {
  if (!entry) return workingEnum.notWorking;

  const { discriminator, end } = entry;

  if (discriminator === workingEnum.working && !end) return workingEnum.working;
  if (discriminator === workingEnum.driving && !end) return workingEnum.driving;
  if (discriminator === workingEnum.break && !end) return workingEnum.break;
  return null;
}
