import React from "react";

export const DrivingStatusBade = ({ label = "Driving" }) => {
  return (
    <div className="inline-flex items-center justify-start rounded-[20px] bg-purple-slate px-3 py-2">
      <div className="text-sm font-semibold text-indigo-300">{label}</div>
    </div>
  );
};

export const WorkingStatusBadge = ({ label = "Clocked in" }) => {
  return (
    <div className="inline-flex items-center justify-start rounded-[20px] bg-emerald-800 px-3 py-2">
      <div className="text-sm font-semibold text-teal-400">{label}</div>
    </div>
  );
};

export const NotWorkingStatusBadge = ({ label = "Not Working" }) => {
  return (
    <div className="inline-flex items-center justify-start rounded-[20px] bg-zinc-700 px-3 py-2">
      <div className="text-sm font-semibold text-white">{label}</div>
    </div>
  );
};
