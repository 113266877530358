import LoadingSpinner from "components/common/LoadingSpinner";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import * as msw from "./mswRegister";
import * as sw from "./swRegister";
import "./utils/polyfills";

const root = ReactDOM.createRoot(document.getElementById("root"));
const loadingRoot = ReactDOM.createRoot(
  document.getElementById("loading-root"),
);

loadingRoot.render(<LoadingSpinner fullScreen width={36} height={36} />);

/* msw is conditionally loaded based on env vars */
msw
  .register()
  .then(({ worker }) => {
    if (!worker) sw.register();
  })
  .finally(() => {
    loadingRoot.unmount();
    root.render(<App />);
  });
