import React from "react";
import Tile from "./Tile";

import { useTodaysTimeSheet } from "hooks/use-timesheets";
import * as tsUtils from "components/Concierge/TimeTracking/TimeTracking.utils";
import {
  DrivingStatusBade,
  WorkingStatusBadge,
} from "components/Concierge/TimeTracking/Badges";
import { useLiveTimeCounter } from "hooks/use-live-time-counter";
import { TimeTrackingButton } from "components/Concierge/TimeTracking/Buttons";
import {
  ViewYourDayButton,
  TimeManagement,
} from "components/Concierge/TimeTracking/TimeSheetDayView";
import CommonButton from "components/common/Button";
import { useTimeTracking } from "hooks/use-timeTracking";
import FadeTransition from "components/common/FadeTransisiton";
import { useScreenBlur } from "hooks/use-screenBlur";

const YourDayTile = () => {
  const { toggleScreenBlur } = useScreenBlur();

  const [showTimeManagement, setShowTimeManagement] = React.useState(false);
  const timeSheetData = useTodaysTimeSheet();

  const { data: timeSheet } = timeSheetData;

  const { startDriving, stopDriving, startWorking, stopWorking } =
    useTimeTracking(timeSheetData);

  const entries = timeSheet?.timeEntries || [];

  const openEntry = entries.find((e) => !e.end);

  const workStatus = tsUtils.getWorkStatus(openEntry);

  const counter = useLiveTimeCounter(openEntry?.start, new Date());

  const toggleTimeManagement = () => {
    toggleScreenBlur();
    setShowTimeManagement(!showTimeManagement);
  };

  return (
    <>
      <Tile>
        <ViewYourDayButton label="Your day" />

        {workStatus !== tsUtils.workingEnum.notWorking && (
          <div className="mt-4 flex items-center justify-between">
            {workStatus === tsUtils.workingEnum.driving && (
              <DrivingStatusBade />
            )}
            {workStatus === tsUtils.workingEnum.working && (
              <WorkingStatusBadge />
            )}
            <span className="text-sm">{counter}</span>
          </div>
        )}
      </Tile>

      <TimeTrackingButton onClick={toggleTimeManagement} />
      <FadeTransition show={showTimeManagement}>
        <TimeManagement
          workStatus={workStatus}
          timestamp={counter}
          onClose={toggleTimeManagement}
        >
          <div className="flex flex-col gap-y-4 p-4">
            {workStatus === tsUtils.workingEnum.notWorking && (
              <>
                <Button onClick={startDriving}>Start driving</Button>
                <Button onClick={startWorking}>Start working</Button>
              </>
            )}

            {workStatus === tsUtils.workingEnum.driving && (
              <>
                <Button onClick={stopDriving}>Stop driving</Button>
                <Button onClick={startWorking}>Start working</Button>
              </>
            )}

            {workStatus === tsUtils.workingEnum.working && (
              <>
                <Button onClick={stopWorking}>Stop working</Button>
              </>
            )}

            <ViewYourDayButton
              label={<span className="text-base">View your day</span>}
              className="rounded-sm border-2 border-zinc-700 p-4"
            />
          </div>
        </TimeManagement>
      </FadeTransition>
    </>
  );
};

const Button = (props) => {
  return (
    <CommonButton
      className="border-zinc-700 bg-zinc-700 py-4 text-white"
      {...props}
    />
  );
};

export default YourDayTile;
