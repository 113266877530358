import { cn } from "@/lib/utils";
import React from "react";
import Button from "./Button";
cn;

const Banner = ({ children }) => {
  return (
    <div className="fixed bottom-4 left-4 right-4 z-20 mx-auto max-w-lg rounded-md bg-white p-8">
      {children}
    </div>
  );
};

const BannerHeader = ({ children }) => {
  return <BannerRow>{children}</BannerRow>;
};

const BannerTitle = ({ text }) => {
  return (
    <div className="flex-1 font-medium text-dark-gray">
      <h3>{text}</h3>
    </div>
  );
};

const BannerImage = ({ src }) => {
  return (
    <div className="basis-2/10 grow-0">
      <img src={src} alt="App Icon" className="w-18" />
    </div>
  );
};

const BannerButton = ({ name, label, className = "", onClick }) => {
  const hassBgOverrides = className?.includes("bg-");
  const hasTextOverrides = className?.includes("text-");

  const hasBgHoverOverrides = className?.includes("hover:bg-");
  const hasTextHoverOverrides = className?.includes("hover:text-");

  const bgCx = hassBgOverrides ? "" : "bg-[#f1f3f5]";
  const bgHoverCx = hasBgHoverOverrides ? "" : "hover:bg-[#f1f3f5]";
  const textCx = hasTextOverrides ? "" : "text-[#8897ae]";
  const textHoverCx = hasTextHoverOverrides ? "" : "hover:text-[#8897ae]";

  return (
    <Button
      className={cn(
        "text-sm font-normal",
        bgCx,
        bgHoverCx,
        textCx,
        textHoverCx,
        className,
      )}
      name={name}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

const BannerRow = ({ children }) => {
  return (
    <div className="flex items-center justify-between gap-x-4">{children}</div>
  );
};

Banner.Row = BannerRow;
Banner.Header = BannerHeader;
Banner.Image = BannerImage;
Banner.Title = BannerTitle;
Banner.Button = BannerButton;

export default Banner;
