import {
  startRegistration,
  startAuthentication,
  browserSupportsWebAuthn,
  browserSupportsWebAuthnAutofill,
} from "@simplewebauthn/browser";

import kohost from "services/kohost";
import { useMutate } from "hooks/use-fetch";

const startPasskeyRegistration = async () => {
  try {
    const response = await kohost.StartRegisterPasskey();
    const data = response.data[0];
    return data;
  } catch (error) {
    console.log(error);
  }
};

const getSavedRegistations = async () => {
  try {
    const response = await kohost.DescribeMyPasskeyRegistrations();
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const usePasskey = () => {
  const authenticate = async (options) => {
    return await startAuthentication(options);
  };

  const registerPasskey = async () => {
    const { challengeId, options } = await startPasskeyRegistration();
    const challengeResponse = await startRegistration(options);
    await kohost.FinishRegisterPasskey({
      data: { challengeId, challengeResponse },
    });
  };

  const registerMutation = useMutate({
    mutationFn: registerPasskey,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return {
    register: registerMutation,
    authenticate,
    getSavedRegistations,
    browserSupportsWebAuthn,
    browserSupportsWebAuthnAutofill,
  };
};
