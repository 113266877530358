import React from "react";
import Tile from "./Tile";
import elevator from "../../img/3d-elevator.png";

const ElevatorTile = () => {
  return (
    <Tile name="Elevator" square>
      <img src={elevator} className="mx-auto max-w-[75%]" alt="Elevator" />
    </Tile>
  );
};

export default ElevatorTile;
