import {
  useFetch,
  useQueryClient,
  useMutate,
  createFetchOptions,
  fetchHandler,
} from "./use-fetch";

import { useProperty } from "./use-property";

import { midnightTodayAtTimezone } from "utils/dates";

const TS_QUERY_KEY = "timeSheets";

export const useTimesheets = (query = {}, opts = { includeStats: false }) => {
  const { currentPropertyId } = useProperty();
  const fetchOptions = createFetchOptions(currentPropertyId, TS_QUERY_KEY);
  const TIMESHEETS = fetchOptions.key;
  const headers = fetchOptions.headers;

  const timesheetQuery = useFetch({
    queryFn: fetchHandler({
      useCase: "ListMyTimeSheets",
      query,
      headers,
    }),
    queryKey: [...TIMESHEETS, query],
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    retry: false,
    meta: {
      cacheById: true,
    },
  });

  const statsQuery = useFetch({
    queryFn: fetchHandler({
      useCase: "DescribeTimeSheetStats",
      query,
      headers,
      options: { firstOnly: true },
    }),
    queryKey: [...TIMESHEETS, "stats", query],
    enabled: opts.includeStats,
    refetchOnWindowFocus: true,
    retry: false,
  });

  return { ...timesheetQuery, stats: statsQuery };
};

export const useTimeSheet = (id = null) => {
  const { currentPropertyId } = useProperty();
  const fetchOptions = createFetchOptions(currentPropertyId, TS_QUERY_KEY);
  const TIMESHEETS = fetchOptions.key;
  const headers = fetchOptions.headers;

  const queryClient = useQueryClient();

  const updateTimeSheetQueryCache = (timesheet) => {
    queryClient.setQueryData([...TIMESHEETS, timesheet.id], timesheet);

    queryClient.setQueriesData({ queryKey: TIMESHEETS }, (old) => {
      if (!old) return [timesheet];

      if (Array.isArray(old)) {
        if (old.length === 0) return [timesheet];
        return old.map((t) => {
          if (t.id === timesheet.id) return timesheet;
          return t;
        });
      } else if (old.id === timesheet.id) return timesheet;
    });
  };

  const timeSheetQuery = useFetch({
    queryFn: fetchHandler({
      useCase: "DescribeTimeSheet",
      data: { id },
      headers,
      options: { firstOnly: true },
    }),
    queryKey: [...TIMESHEETS, id],
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: Boolean(id),
    refetchOnWindowFocus: true,
    retry: false,
  });

  const createTimeSheet = (data) => {
    const handler = fetchHandler({
      useCase: "CreateTimeSheet",
      data,
      headers,
      options: { firstOnly: true },
    });
    return handler();
  };

  const updateTimeSheetTimeEntry = (data) => {
    const handler = fetchHandler({
      useCase: "UpdateTimeSheetTimeEntry",
      data,
      headers,
      options: { firstOnly: true },
    });
    return handler();
  };

  const createTimeSheetTimeEntry = (data) => {
    const handler = fetchHandler({
      useCase: "CreateTimeSheetTimeEntry",
      data,
      headers,
      options: { firstOnly: true },
    });
    return handler();
  };

  const deleteTimeSheetTimeEntry = (data) => {
    const handler = fetchHandler({
      useCase: "DeleteTimeSheetTimeEntry",
      data,
      headers,
      options: { firstOnly: true },
    });
    return handler();
  };

  const createTimeSheetMutation = useMutate({
    mutationFn: createTimeSheet,
    onSuccess: updateTimeSheetQueryCache,
  });

  const createTimeSheetTimeEntryMutation = useMutate({
    mutationFn: createTimeSheetTimeEntry,
    onSuccess: updateTimeSheetQueryCache,
  });

  const updateTimeSheetTimeEntryMutation = useMutate({
    mutationFn: updateTimeSheetTimeEntry,
    onSuccess: updateTimeSheetQueryCache,
  });

  const deleteTimeSheetTimeEntryMutation = useMutate({
    mutationFn: deleteTimeSheetTimeEntry,
    onSuccess: updateTimeSheetQueryCache,
  });

  return {
    ...timeSheetQuery,
    createTimeSheet: createTimeSheetMutation,
    createTimeSheetTimeEntry: createTimeSheetTimeEntryMutation,
    updateTimeSheetTimeEntry: updateTimeSheetTimeEntryMutation,
    deleteTimeSheetTimeEntry: deleteTimeSheetTimeEntryMutation,
  };
};

export const useTodaysTimeSheet = () => {
  const now = new Date();
  const { property } = useProperty();
  const timezone = property?.timezone;

  const targetDay = midnightTodayAtTimezone(now, { timezone });
  const tsQuery = { day: targetDay };
  const { data: timesheets } = useTimesheets(tsQuery);

  const ts = timesheets && timesheets[0];
  const tsId = ts ? ts.id : null;

  return useTimeSheet(tsId);
};
