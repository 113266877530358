import React from "react";
import alarm from "../../img/3d-alarm-v2.png";
import Tile from "./Tile";

const SecurityTile = () => {
  return (
    <Tile name="Security" square link="/room-control/security">
      <img
        src={alarm}
        alt="Security"
        className="mx-auto h-full w-full object-contain"
      />
    </Tile>
  );
};

export default SecurityTile;
