import React from "react";

const AnimatedLogo = React.forwardRef(({ src, onLoad }, ref) => {
  return (
    <img
      ref={(node) => (ref.current = node)}
      src={src}
      alt="Logo"
      className="relative max-h-[75px] max-w-[200px]"
      onLoad={onLoad}
    />
  );
});

AnimatedLogo.displayName = "AnimatedLogo";

export default AnimatedLogo;
