export function isPWA() {
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    window.matchMedia("(display-mode: fullscreen)").matches ||
    window.navigator.standalone === true
  );
}

export function isAndroid() {
  if (!window) return false;
  return navigator.userAgent.toLowerCase().includes("android");
}

export function isIOS() {
  if (!window) return false;
  return (
    navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
    navigator.userAgent.match(/AppleWebKit/)
  );
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isAppleDevice() {
  const userAgent = navigator.userAgent || window.opera;
  // Check for iOS and macOS devices
  return /Macintosh|MacIntel|MacPPC|Mac68K|iPhone|iPad|iPod/.test(userAgent);
}

export function getMobileOs() {
  if (isAndroid()) return "android";
  if (isIOS()) return "ios";
  return "unknown";
}

export const getCountryCode = () => {
  const countryCode = window.navigator.language.split("-")[1];
  return countryCode || "US";
};
