import React from "react";

import { ReactComponent as Clock } from "../icons/clock.svg";
import { ReactComponent as Trash } from "../icons/trash.svg";
import { ReactComponent as Pencil } from "../icons/pencil.svg";

export const TimeTrackingButton = ({ onClick }) => {
  return (
    <div className="fixed bottom-4 right-4 z-30">
      <button
        onClick={(e) => onClick(e)}
        className="flex h-20 w-20 items-center justify-center rounded-full border-0 bg-white p-0 shadow-xl focus:outline-none"
      >
        <Clock className="w-8" />
      </button>
    </div>
  );
};

export const EditTimeEntryButton = ({ onClick }) => {
  return (
    <div className="fixed bottom-4 right-28 z-30">
      <button
        onClick={(e) => onClick(e)}
        className="flex h-20 w-20 items-center justify-center rounded-full border-0 bg-white p-0 shadow-xl focus:outline-none"
      >
        <Pencil className="w-8" />
      </button>
    </div>
  );
};

export const DeleteTimeEntryButton = ({ onClick }) => {
  return (
    <div className="fixed bottom-4 right-4 z-30">
      <button
        onClick={(e) => onClick(e)}
        className="flex h-20 w-20 items-center justify-center rounded-full border-0 bg-white p-0 shadow-xl focus:outline-none"
      >
        <Trash className="w-6" />
      </button>
    </div>
  );
};
