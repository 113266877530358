import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as BackArrow } from "../icons/back-arrow.svg";
import { ReactComponent as Add } from "../icons/add.svg";
import { DrivingStatusBade, WorkingStatusBadge } from "./Badges";

function getStatusBadge(workStatus) {
  switch (workStatus) {
    case "working":
      return <WorkingStatusBadge />;
    case "driving":
      return <DrivingStatusBade />;
    case "notWorking":
      return null;
    default:
      return null;
  }
}

const TimeTrackingHeader = ({
  subtitle,
  date = new Date(),
  onClose,
  onAdd,
  onChange,
  workStatus = "notWorking",
  workTimeStamp,
}) => {
  //convert date to string and remove time
  const value = date.toISOString().split("T")[0];
  const today = new Date().toISOString().split("T")[0];

  const changeDay = (e) => {
    // convert YYYY-MM-DD to MM-DD-YY
    const date = e.target.value.split("-");
    const newDate = `${date[1]}-${date[2]}-${date[0].slice(2)}`;
    onChange(newDate);
  };

  return (
    <div className="sticky top-0 z-50 w-full bg-dark-gray shadow-xl-bottom">
      <div className="z-10 border-b border-b-zinc-700 bg-dark-gray pb-6 pt-6 shadow-lg">
        <div className="grid grid-cols-3 items-center justify-between px-4">
          <div className="w-auto">
            <button className="py-4" onClick={onClose}>
              <BackArrow />
            </button>
          </div>

          <h1 className="text-center text-lg">
            <input
              className="bg-dark-gray text-white"
              type="date"
              value={value}
              max={today}
              onChange={changeDay}
            />
            {subtitle && (
              <span className="block text-xs uppercase text-muted">
                {subtitle}
              </span>
            )}
          </h1>
          <div className="w-auto text-right">
            <button className="py-4" onClick={onAdd}>
              <Add />
            </button>
          </div>
        </div>
      </div>
      {workStatus !== "notWorking" && (
        <div className="flex items-center justify-between p-4">
          <div className="flex-1">{getStatusBadge(workStatus)}</div>
          <div className="text-sm">{workTimeStamp}</div>
        </div>
      )}
    </div>
  );
};

TimeTrackingHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onClose: PropTypes.func,
  onAdd: PropTypes.func.isRequired,
  workStatus: PropTypes.oneOf(["working", "driving", "notWorking", "break"])
    .isRequired,
  workTimeStamp: PropTypes.any,
};

export default TimeTrackingHeader;
