import React from "react";
import Tile from "./Tile";
import experiences from "../../img/3d-experiences.png";

const ExperiencesTile = () => {
  return (
    <Tile name="Experiences" square>
      <div className="max-h-full">
        <img
          src={experiences}
          alt="Experiences"
          className="mx-auto max-h-full"
        />
      </div>
    </Tile>
  );
};

export default ExperiencesTile;
