import React from "react";
import Modal from "components/common/Modal/WarningModal";

const DeleteTimeEntryModal = ({ onConfirm, onCancel }) => {
  return (
    <Modal
      title={`Are you sure you want to delete this time block? This cannot be reversed.`}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonLabel="Delete"
    ></Modal>
  );
};

export default DeleteTimeEntryModal;
