import { differenceInCalendarDays, format } from "date-fns";

export const statusOptions = [
  {
    value: "open",
    label: "Open",
  },
  {
    value: "pending",
    label: "Awaiting Reply",
  },
  {
    value: "solved",
    label: "Solved",
  },
];

export const tagOptions = [
  {
    value: "housekeeping",
    label: "Housekeeping",
    color: "primary",
  },
  {
    value: "engineering",
    label: "Engineering",
    color: "yellow",
  },
  {
    value: "food-beverage",
    label: "Food + Beverage",
    color: "success",
  },
];

export function getMessageTime(date) {
  return format(new Date(date), "h:mm a");
}

export function getMessageTimeLabel(date) {
  const now = new Date();
  const isToday = differenceInCalendarDays(now, new Date(date)) === 0;
  const isYesterday = differenceInCalendarDays(now, new Date(date)) === 1;
  if (isToday) return "Today";
  if (isYesterday) return "Yesterday";
  return format(new Date(date), "MMM dd");
}

export function groupMessageReducer(acc, currentMessage) {
  const now = new Date();
  const messageDate = new Date(currentMessage.timestamp);
  const daysDiff = differenceInCalendarDays(now, messageDate);
  let groupLabel;
  if (daysDiff === 0) {
    groupLabel = "";
  }

  if (daysDiff === 1) {
    groupLabel = "Yesterday";
  }

  if (daysDiff >= 2) {
    groupLabel = format(messageDate, "MMM dd");
  }

  const groupLabelIndex = acc.findIndex((group) => group.label === groupLabel);

  if (groupLabelIndex < 0) {
    acc.push({
      label: groupLabel,
      messages: [currentMessage],
    });
  } else {
    acc[groupLabelIndex].messages.push(currentMessage);
  }
  return acc;
}

export function numUnreadMessages({ conversation }, user) {
  if (!conversation || conversation.length === 0) return 0;
  const count = conversation.filter(
    (msg) => msg && msg.readBy && msg.readBy.indexOf(user) < 0,
  );
  return count.length;
}

export function isTicketAssignedToMe({ ticket, user }) {
  return ticket.assignedTo?.userId === user?.id;
}

export function isTicketRequestedByMe({ ticket, user }) {
  return ticket.requester?.userId === user?.id;
}

export function isTicketOpenedByMe({ ticket, user }) {
  return ticket.openedBy?.userId === user?.id;
}

export function isTicketRequestedBySystem({ ticket }) {
  return (
    ticket.requester?.systemId !== null &&
    ticket.requester?.systemId !== undefined
  );
}

export function getTicketRequesterPhoto({ ticket }) {
  const requester = ticket.requester;
  if (ticket.requester?.userPhoto) return requester.userPhoto;
  if (ticket.requester?.systemPhoto) return requester.systemPhoto;
  return null;
}

export function getTicketAssignedToPhoto({ ticket, propertyIcon }) {
  const assignedTo = ticket.assignedTo;
  if (assignedTo?.userPhoto) return assignedTo.userPhoto;
  else return propertyIcon;
}

export function getTimeSince(startDate = new Date(), endDate) {
  if (!endDate) return null;
  // get display distance as Hours:Minutes:Seconds
  const distance = Math.abs(new Date(startDate) - new Date(endDate));
  let hours = Math.floor(distance / 3600000);
  let minutes = Math.floor((distance % 3600000) / 60000);
  let seconds = Math.floor((distance % 60000) / 1000);

  // make sure hours, minutes, and seconds are two digits
  hours = hours.toString().padStart(2, "0");
  minutes = minutes.toString().padStart(2, "0");
  seconds = seconds.toString().padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
}
