import React from "react";
import Tile from "./Tile";
import rentals from "../../img/3d-rentals.png";

const RentalsTile = () => {
  return (
    <Tile name="Rentals">
      <div className="max-h-full">
        <img src={rentals} alt="Rentals" className="mx-auto max-h-full" />
      </div>
    </Tile>
  );
};

export default RentalsTile;
