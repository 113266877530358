import { useProperty } from "./use-property";

export const useCheckOut = () => {
  const { property } = useProperty();
  const checkOutConfig = property?.appFeatures?.CheckOut || {};

  return {
    checkOutConfig,
  };
};
