import React from "react";

export const NotFound = () => {
  return (
    <div className="container mx-auto flex h-screen flex-col justify-center px-4 h-screen-ios">
      <div className="mx-auto flex h-full max-w-2xl flex-col items-center justify-center text-center">
        <h1 className="mb-2 text-2xl font-medium">This page does not exist.</h1>
        <p className="mb-4 text-lg">Looks like you found a broken link.</p>
        <button
          onClick={() => {
            window.location.href = "/";
          }}
          className="block rounded-sm bg-green px-8 py-2 text-white"
        >
          Back Home
        </button>
      </div>
    </div>
  );
};

export default NotFound;
