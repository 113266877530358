import React from "react";
import { isEqual } from "./isEqual";
export function useStateWithTimeout(incomingData, timeout) {
  if (!Array.isArray(incomingData)) {
    throw new Error("initialState must be an array");
  }

  if (typeof timeout !== "number") {
    throw new Error("timeout must be a number");
  }

  if (timeout < 0) {
    throw new Error("timeout must be greater than or equal to 0");
  }

  if (incomingData.length > 0) {
    // check to ensure that all items in initialState have an id property
    if (!incomingData.every((item) => item.id)) {
      throw new Error("All items in initialState must have an id property");
    }
  }

  const [data, setData] = React.useState(incomingData);
  const timeouts = React.useRef({});

  const setDataWithTimeout = React.useCallback(
    (newData) => {
      setData((prevData) => {
        return newData.map((newItem) => {
          const existingItem = prevData.find((item) => item.id === newItem.id);

          if (existingItem) {
            clearTimeout(timeouts.current[newItem.id]);

            timeouts.current[newItem.id] = setTimeout(() => {
              delete timeouts.current[newItem.id];
            }, timeout);

            return { ...existingItem, ...newItem };
          } else {
            return newItem;
          }
        });
      });
    },
    [timeout],
  );

  const setById = React.useCallback(
    (id, newData) => {
      setData((prevData) => {
        const existingItem = prevData.find((item) => item.id === id);

        if (existingItem) {
          clearTimeout(timeouts.current[id]);

          timeouts.current[id] = setTimeout(() => {
            delete timeouts.current[id];
          }, timeout);

          return prevData.map((item) =>
            item.id === id ? { ...item, ...newData } : item,
          );
        } else {
          return [...prevData, newData];
        }
      });
    },
    [timeout],
  );

  React.useEffect(() => {
    if (incomingData.length === 0) {
      return setData([]);
    }

    if (isEqual(incomingData, data)) {
      return;
    }

    // compare initialState to data

    const toUpdate = incomingData
      .map((item) => {
        const existingItem = data.find((i) => i.id === item.id);

        if (existingItem) {
          if (timeouts.current[item.id]) {
            return null;
          }

          const isSame = isEqual(existingItem, item);
          if (isSame) return null;

          return { ...existingItem, ...item };
        } else {
          return item;
        }
      })
      .filter(Boolean);

    const toRemove = data.filter(
      (item) => !incomingData.some((i) => i.id === item.id),
    );

    if (toUpdate.length > 0) {
      setData((prevData) => {
        const newState = [...prevData];

        toUpdate.forEach((newItem) => {
          const existingItem = newState.find((i) => i.id === newItem.id);
          if (existingItem) {
            const index = newState.indexOf(existingItem);
            newState[index] = newItem;
          } else {
            newState.push(newItem);
          }
        });

        return newState;
      });
    }

    if (toRemove.length > 0) {
      setData((prevData) => {
        const newState = prevData.filter(
          (item) => !toRemove.some((i) => i.id === item.id),
        );
        return newState;
      });
    }
  }, [incomingData, data, timeout]);

  return {
    state: data,
    setState: setDataWithTimeout,
    setStateById: setById,
  };
}
