import React, { useState, useEffect } from "react";
import Transition from "react-transition-group/Transition";
import gsap from "gsap";
import { isIOS, isPWA } from "utils/browser";
import a2hs from "utils/a2hs";
import Banner from "../Banner";
import { useProperty } from "hooks/use-property";
import share from "./icons/share.svg";
import add from "./icons/add-home.svg";

const AddToHomeScreen = () => {
  const [askA2HS, setAskA2HS] = useState(false);
  const [showHowToA2HS, setShowHowToA2HS] = useState(false);

  const { organization, property, isHospitality } = useProperty();

  const propertyName = property?.name;
  const appManifest = organization?.appManifest;

  const icon = appManifest?.icons[0]?.src;

  useEffect(() => {
    const checkPwaSignals = () => {
      if (!isIOS()) return setAskA2HS(false);
      if (isPWA()) {
        return setAskA2HS(false);
      } else {
        let pwaCookie = document.cookie.match(
          /^(.*;)?\s*pwaHide\s*=\s*[^;]+(.*)?$/,
        );
        let pwaInstalledCookie = document.cookie.match(
          /^(.*;)?\s*pwaInstalled\s*=\s*[^;]+(.*)?$/,
        );

        // if the cookie does not exist, show the prompt
        if (!pwaCookie && !pwaInstalledCookie) {
          setAskA2HS(true);
        }
      }
    };

    checkPwaSignals();
  }, []);

  const hidePwaBanner = () => {
    let date = new Date();
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
    let cookieExpires = "; expires=" + date.toGMTString();
    document.cookie = "pwaHide=1" + cookieExpires;
    setAskA2HS(false);
  };

  const displayInstructions = () => {
    let showInstructions = false;

    if (window.deferredPrompt) {
      a2hs.handleA2hs();
    } else {
      showInstructions = true;
    }

    setAskA2HS(false);
    setShowHowToA2HS(showInstructions);
  };

  const title = isHospitality
    ? `Would you like to add ${propertyName} to your Home Screen during your stay?`
    : `Would you like to add ${propertyName} to your Home Screen?`;

  return (
    <>
      <A2HSBanner
        show={askA2HS}
        showInstructions={showHowToA2HS}
        icon={icon}
        title={title}
        onConfirm={displayInstructions}
        onHide={hidePwaBanner}
      />
      <A2HSInstructions
        show={showHowToA2HS}
        onHide={() => setShowHowToA2HS(false)}
      />
    </>
  );
};

const A2HSBanner = ({
  show,
  showInstructions = false,
  icon,
  title,
  onConfirm,
  onHide,
}) => {
  return (
    <Transition
      in={show}
      timeout={500}
      unmountOnExit={true}
      onExit={(n) => {
        // Move down if no instructions
        if (!showInstructions) gsap.to(n, { duration: 0.5, y: 1000 });
        // Otherwise, move left
        else gsap.to(n, { duration: 0.5, x: -500 });
      }}
      onEnter={(n) => {
        gsap.set(n, { y: 500, zIndex: 50 });
        gsap.to(n, { duration: 1, y: 0, delay: 2 });
      }}
    >
      <Banner>
        <div className="mb-4">
          <Banner.Header>
            <Banner.Image src={icon} />
            <Banner.Title text={title} />
          </Banner.Header>
        </div>
        <Banner.Row>
          <Banner.Button
            className="w-full"
            label="No Thanks"
            onClick={onHide}
          />
          <Banner.Button
            className="w-full bg-[#e2f1ff] text-[#349dff]"
            label="Yes"
            onClick={onConfirm}
          />
        </Banner.Row>
      </Banner>
    </Transition>
  );
};

const A2HSInstructions = ({ onHide, show }) => {
  return (
    <Transition
      in={show}
      timeout={500}
      unmountOnExit={true}
      onExit={(n) => {
        gsap.to(n, { duration: 0.5, y: 500 });
      }}
      onEnter={(n) => {
        gsap.set(n, { x: 500, y: 0 });
        gsap.to(n, { x: 0, duration: 0.5, delay: 0 });
      }}
    >
      <Banner>
        <div className="mb-4">
          <Banner.Row>
            <div className="w-7 shrink grow-0">
              <img src={share} alt="App Icon" className="mx-auto h-7" />
            </div>
            <Banner.Title text="1. Tap the Safari share icon" />
          </Banner.Row>
        </div>
        <div className="mb-4">
          <Banner.Row>
            <div className="w-7 shrink grow-0">
              <img src={add} alt="App Icon" className="w-7" />
            </div>
            <Banner.Title text={`2. Tap "Add to Home Screen"`} />
          </Banner.Row>
        </div>
        <Banner.Row>
          <Banner.Button label="Done" className="w-full" onClick={onHide} />
        </Banner.Row>
      </Banner>
    </Transition>
  );
};

export default AddToHomeScreen;
