import { cn } from "@/lib/utils";
import React from "react";
import { Link } from "react-router-dom";

const Tile = ({
  name,
  subtitle,
  className = "",
  subtitleClassName = "",
  children,
  link,
  target = "_self",
}) => {
  const cx = cn(
    "bg-light-gray",
    "rounded",
    "shadow-lg",
    "flex",
    "flex-col",
    "flex-1",
    "justify-stretch",
    "items-stretch",
    "overflow-hidden",
    "w-full",
    "h-full",
    "relative",
    "grow",
    "p-4",

    className,
  );

  const Wrapper = link ? Link : "div";

  return (
    <Wrapper className={cx} to={link} target={target}>
      <div className="relative flex-1">
        <div className="absolute inset-0 max-w-full p-4">{children}</div>

        {name && (
          <p className="absolute -bottom-2 left-0 flex w-full shrink-0 items-center justify-between text-lg font-medium leading-none">
            {name}
            {subtitle && (
              <span
                className={cn(
                  "rounded-lg px-1 py-0.5 text-xs",
                  subtitleClassName,
                )}
              >
                {subtitle}
              </span>
            )}
          </p>
        )}
      </div>
    </Wrapper>
  );
};

export default Tile;
