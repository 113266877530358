import React from "react";

const AnimatedSplash = React.forwardRef(({ src, onLoad }, ref) => {
  return (
    <img
      ref={(node) => (ref.current = node)}
      src={src}
      alt={src ? "Splash" : ""}
      className="absolute top-0 -z-10 h-full min-w-min opacity-0"
      onLoad={onLoad}
    />
  );
});

AnimatedSplash.displayName = "AnimatedSplash";

export default AnimatedSplash;
