export const sortByName = (a, b) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

export const sortByDate = (a, b) => {
  const dateA = new Date(a?.timestamp);
  const dateB = new Date(b?.timestamp);
  return dateA - dateB;
};

const statusOrder = {
  checkedIn: 1,
  reserved: 2,
  checkedOut: 3,
  cancelled: 4,
  missed: 5,
  noShow: 6,
};

function getStatusPriority(user) {
  if (!user.reservations || user.reservations.length === 0) {
    return Infinity;
  }

  let highestPriority = Infinity;
  for (let reservation of user.reservations) {
    const currentPriority = statusOrder[reservation.status] || 6;
    highestPriority = Math.min(highestPriority, currentPriority);
  }

  return highestPriority;
}

function getLatestCheckInDateTime(user, status) {
  let lastest = 0;
  const reservations = user.reservations || [];
  const filtered = reservations.filter((r) => r.status === status);
  for (let reservation of filtered) {
    const date = new Date(reservation.checkInDateTime).getTime();
    if (date > lastest) {
      lastest = date;
    }
  }
  return lastest;
}

function getEarliestCheckOutDateTime(user, status) {
  let earliest = Infinity;
  const reservations = user.reservations || [];
  const filtered = reservations.filter((r) => r.status === status);
  for (let reservation of filtered) {
    const date = new Date(reservation.checkOutDateTime).getTime();
    if (date < earliest) {
      earliest = date;
    }
  }
  return earliest;
}

export const sortUsersByReservationStatus = (a, b) => {
  const statusA = getStatusPriority(a);
  const statusB = getStatusPriority(b);

  return statusA - statusB;
};

export const sortUsersByReservationCheckInDate = (status) => {
  return (a, b) => {
    const dateA = getLatestCheckInDateTime(a, status);
    const dateB = getLatestCheckInDateTime(b, status);
    return dateA - dateB;
  };
};

export const sortUsersByReservationCheckOutDate = (status) => {
  return (a, b) => {
    const dateA = getEarliestCheckOutDateTime(a, status);
    const dateB = getEarliestCheckOutDateTime(b, status);
    return dateA - dateB;
  };
};
